var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.lawContent.content, function (law, index) {
      return _c("div", { key: index + "content" }, [
        law.name
          ? _c(
              "div",
              [
                _c("law-item", {
                  attrs: {
                    propData: law,
                    lawTitle: _vm.lawTitle,
                    currentObj: _vm.currentObj,
                  },
                  on: {
                    collect: _vm.emitCollect,
                    changeActiveFn: _vm.changeActive,
                    showLinkPage: _vm.showOtherPage,
                    copy: _vm.handleCopy,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }