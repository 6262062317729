var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lawCorrelation" },
    [
      _c(
        "van-collapse",
        {
          attrs: { accordion: "" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "van-collapse-item",
            { attrs: { title: "基本信息", name: "1" } },
            _vm._l(_vm.lawContent.more_info, function (more_info, index) {
              return _c(
                "div",
                { key: index + "more_info", staticClass: "basic" },
                [
                  _c("p", { staticClass: "Namebasic" }, [
                    _vm._v(_vm._s(more_info.name)),
                  ]),
                  _c("p", { staticClass: "basicc" }, [_vm._v(":")]),
                  _c("p", { staticClass: "basicValue" }, [
                    _vm._v(_vm._s(more_info.value)),
                  ]),
                ]
              )
            }),
            0
          ),
          _c("van-collapse-item", { attrs: { title: "背景资料", name: "2" } }),
          _c(
            "van-collapse-item",
            { attrs: { title: "引用法规", name: "3" } },
            _vm._l(
              _vm.lawContent.meditate_data,
              function (meditate_data, index) {
                return _c(
                  "div",
                  { key: index + "meditate_data", staticClass: "regulations" },
                  [_c("span", [_vm._v(_vm._s(meditate_data.title))])]
                )
              }
            ),
            0
          ),
          _c("van-collapse-item", {
            attrs: { title: "引用本篇的法规、判例、文章", name: "4" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }