var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "law-detail-box" },
    [
      _vm.showTitleBar
        ? _c(
            "fb-header",
            {
              attrs: {
                slot: "header",
                customer: true,
                title: _vm.Title || _vm.title,
                isdet: true,
                fixed: "",
              },
              on: { clickTitle: _vm.clickTitle },
              slot: "header",
            },
            [
              !this.$route.query || !this.$route.query.docid
                ? _c("fb-button", {
                    attrs: {
                      slot: "left",
                      icon: "back",
                      size: "small",
                      type: "primary",
                    },
                    on: { click: _vm.clickback },
                    slot: "left",
                  })
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "copy",
                  staticStyle: { flex: "1" },
                  attrs: { slot: "copy" },
                  slot: "copy",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "pattern",
                      on: { click: _vm.changePattern },
                    },
                    [_vm._v(_vm._s(_vm.pattern === "简洁" ? "完整" : "简洁"))]
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showCopy,
                        expression: "showCopy",
                      },
                    ],
                    staticClass: "iconfont icon-fuzhi",
                    on: { click: _vm.copyTitle },
                  }),
                ]
              ),
              _c(
                "fb-button",
                {
                  attrs: {
                    slot: "right",
                    icon: "menu",
                    size: "small",
                    type: "primary",
                  },
                  on: {
                    "!click": function ($event) {
                      $event.stopPropagation()
                      return _vm.liste.apply(null, arguments)
                    },
                  },
                  slot: "right",
                },
                [
                  [
                    _vm.collect
                      ? _c("div", { staticClass: "menu" }, [
                          _c("div", { on: { click: _vm.download } }, [
                            _vm._v("分享"),
                          ]),
                          !this.$route.query || !this.$route.query.lawid
                            ? _c("div", { on: { click: _vm.collection } }, [
                                _vm._v("收藏"),
                              ])
                            : _vm._e(),
                          _vm.ua !== "fb-iOS-Webview" &&
                          (!this.$route.query || !this.$route.query.lawid)
                            ? _c("div", { on: { click: _vm.download2 } }, [
                                _vm._v("下载"),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          ref: "container",
          staticClass: "highlight-area",
          class: _vm.pattern === "完整" ? "" : "law-detail-scroll",
          attrs: { id: "lawDetail" },
          on: { scroll: _vm.taggleBottomScroll },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.skydriveif,
                  expression: "!skydriveif",
                },
              ],
            },
            [
              _vm.pattern === "完整"
                ? _c(
                    "div",
                    { ref: "lawDetail", staticClass: "law-content-wrap" },
                    [
                      _c("fb-loading", { attrs: { loading: _vm.loading } }),
                      _vm.lawDetail && _vm.lawDetail.content
                        ? _c("law-content", {
                            attrs: {
                              lawContent: _vm.lawDetail,
                              catlog: _vm.catlog,
                              title: this.title || this.Title,
                              lawid: _vm.id,
                              id: "contena",
                            },
                            on: { collect: _vm.collectContent },
                          })
                        : _c(
                            "div",
                            { staticClass: "loading" },
                            [
                              _c(
                                "van-list",
                                {
                                  attrs: { finished: _vm.finished },
                                  model: {
                                    value: _vm.loading,
                                    callback: function ($$v) {
                                      _vm.loading = $$v
                                    },
                                    expression: "loading",
                                  },
                                },
                                [
                                  _c("div", {
                                    ref: "detailContainer",
                                    staticClass: "container",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                      _c("div", { staticClass: "icon-icon" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.reportCount,
                                expression: "reportCount",
                              },
                            ],
                            ref: "collect",
                            staticClass: "iconfonts",
                            style: {
                              background: _vm.isCollect ? "#f4a83b" : "",
                              position: "relative",
                            },
                            on: { click: _vm.handleCollect },
                          },
                          [
                            _c(
                              "transition",
                              { attrs: { name: "slide-fade" } },
                              [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showAdd,
                                        expression: "showAdd",
                                      },
                                    ],
                                    staticClass: "badge-box",
                                  },
                                  [_vm._v(" +1 ")]
                                ),
                              ]
                            ),
                            _c(
                              "transition",
                              { attrs: { name: "slide-fade" } },
                              [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showReduce,
                                        expression: "showReduce",
                                      },
                                    ],
                                    staticClass: "badge-box",
                                  },
                                  [_vm._v(" -1 ")]
                                ),
                              ]
                            ),
                            _c("span", [_vm._v("集")]),
                            _c("span", { staticClass: "count" }, [
                              _vm._v(_vm._s(this.reportCount)),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            ref: "zhiding",
                            staticClass: "iconfonts zhiding",
                            on: { click: _vm.clickZhiding },
                          },
                          [_c("span", { staticClass: "iconfont icon-zhiding" })]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "iconfonts mulu",
                            on: { click: _vm.clickShow },
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont icon-zhediexiaoguo1",
                            }),
                          ]
                        ),
                        !_vm.searchWordsShow
                          ? _c(
                              "div",
                              {
                                staticClass: "iconfonts fanhui",
                                on: { click: _vm.clickShowSearchBar },
                              },
                              [
                                _c("span", {
                                  staticClass: "iconfont icon-sousuo",
                                }),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "iconfonts fanhui",
                                staticStyle: { visibility: "hidden" },
                              },
                              [
                                _c("span", {
                                  staticClass: "iconfont icon-sousuo",
                                }),
                              ]
                            ),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.searchWordsShow,
                              expression: "searchWordsShow",
                            },
                          ],
                          staticClass: "search-words",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "words" },
                            _vm._l(_vm.wordsList, function (item, i) {
                              return _c("div", { key: i }, [
                                _vm._v(_vm._s(item)),
                                _c("i", {
                                  staticClass: "iconfont icon-clear",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteWord(i)
                                    },
                                  },
                                }),
                              ])
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "input" },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "#409eff",
                                    "margin-right": "10px",
                                  },
                                  on: { click: _vm.closeSearch },
                                },
                                [_vm._v("退出")]
                              ),
                              _c(
                                "div",
                                { staticStyle: { flex: "1" } },
                                [
                                  _c("van-field", {
                                    ref: "searchKeyWordInput",
                                    attrs: { placeholder: "请输入关键词" },
                                    on: {
                                      input: _vm.handleChange,
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.hanldeKeyEnter.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      blur: _vm.handleBlur,
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        return _vm.hanldeKeySpace.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.value,
                                      callback: function ($$v) {
                                        _vm.value = $$v
                                      },
                                      expression: "value",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticStyle: { margin: "0 5px" } }, [
                                _c(
                                  "div",
                                  { staticStyle: { color: "#409eff" } },
                                  [_vm._v(_vm._s(_vm.currentPos))]
                                ),
                                _c("div", { staticStyle: { color: "#ccc" } }, [
                                  _vm._v(_vm._s(_vm.times)),
                                ]),
                              ]),
                              _c("van-icon", {
                                attrs: { name: "arrow-down" },
                                on: { click: _vm.optNext },
                              }),
                              _c("van-icon", {
                                attrs: { name: "arrow-up" },
                                on: { click: _vm.optPrev },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "van-popup",
                        {
                          staticClass: "lawMenu",
                          attrs: { position: "right" },
                          model: {
                            value: _vm.show,
                            callback: function ($$v) {
                              _vm.show = $$v
                            },
                            expression: "show",
                          },
                        },
                        [
                          _c(
                            "van-tabs",
                            {
                              attrs: { color: "#569af0" },
                              model: {
                                value: _vm.active,
                                callback: function ($$v) {
                                  _vm.active = $$v
                                },
                                expression: "active",
                              },
                            },
                            [
                              _c(
                                "van-tab",
                                { attrs: { title: "目录" } },
                                [
                                  _c("law-menu", {
                                    attrs: { lawContent: _vm.lawDetail },
                                    on: { jumpTo: _vm.jumpToTitle },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "van-tab",
                                { attrs: { title: "相关内容" } },
                                [
                                  _c("law-correlation", {
                                    attrs: { lawContent: _vm.lawDetail },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.pattern === "简洁"
                ? _c(
                    "div",
                    { ref: "lawDetail", staticClass: "law-content-wrap" },
                    [
                      _c("fb-loading", { attrs: { loading: _vm.loading } }),
                      _vm.lawDetail && _vm.lawDetail.content
                        ? _c("law-content-concise", {
                            attrs: {
                              lawContent: _vm.lawDetail,
                              catlog: _vm.catlog,
                              title: this.title || this.Title,
                              lawid: _vm.id,
                              id: "contena",
                            },
                            on: { collect: _vm.collectContent },
                          })
                        : _c(
                            "div",
                            { staticClass: "loading" },
                            [
                              _c(
                                "van-list",
                                {
                                  attrs: { finished: _vm.finished },
                                  model: {
                                    value: _vm.loading,
                                    callback: function ($$v) {
                                      _vm.loading = $$v
                                    },
                                    expression: "loading",
                                  },
                                },
                                [
                                  _c("div", {
                                    ref: "detailContainer",
                                    staticClass: "container",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                      _c("div", { staticClass: "icon-icon" }, [
                        _c(
                          "div",
                          {
                            ref: "zhiding",
                            staticClass: "iconfonts zhiding",
                            on: { click: _vm.clickZhiding },
                          },
                          [_c("span", { staticClass: "iconfont icon-zhiding" })]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "iconfonts mulu",
                            on: { click: _vm.clickShow },
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont icon-zhediexiaoguo1",
                            }),
                          ]
                        ),
                      ]),
                      _c(
                        "van-popup",
                        {
                          staticClass: "lawMenu",
                          attrs: { position: "right" },
                          model: {
                            value: _vm.show,
                            callback: function ($$v) {
                              _vm.show = $$v
                            },
                            expression: "show",
                          },
                        },
                        [
                          _c(
                            "van-tabs",
                            {
                              attrs: { color: "#569af0" },
                              model: {
                                value: _vm.active,
                                callback: function ($$v) {
                                  _vm.active = $$v
                                },
                                expression: "active",
                              },
                            },
                            [
                              _c(
                                "van-tab",
                                { attrs: { title: "目录" } },
                                [
                                  _c("law-menu", {
                                    attrs: { lawContent: _vm.lawDetail },
                                    on: { jumpTo: _vm.jumpToTitle },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.haha
                ? _c(
                    "div",
                    { staticClass: "skyDrive" },
                    [
                      _c("skyDrive", {
                        attrs: {
                          listid: _vm.id,
                          Type: "lawid",
                          listname: this.title || this.Title,
                        },
                        on: { CancelCollect: _vm.close, getId: _vm.getId },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm.skydriveif
            ? _c(
                "div",
                { staticClass: "sky-drive-if" },
                [
                  _c("sky-drive", {
                    attrs: { skydriveif: _vm.skydriveif },
                    on: { close: _vm.closesky },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showReport
            ? _c(
                "div",
                { staticClass: "collect-report" },
                [
                  _c("searchReport", {
                    attrs: { from: "law" },
                    on: { close: _vm.closeReport },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "van-popup",
        {
          staticClass: "guidance-van-popup",
          attrs: { "close-on-click-overlay": false },
          model: {
            value: _vm.guidanceShow,
            callback: function ($$v) {
              _vm.guidanceShow = $$v
            },
            expression: "guidanceShow",
          },
        },
        [
          _c("guidance", {
            attrs: { type: "web", animationId: "2001" },
            on: { close: _vm.closeGuidancePopup },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }