var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-item" }, [
    _c("div", { staticClass: "text" }, [
      _c("span", {
        domProps: { innerHTML: _vm._s(_vm.str) },
        on: { click: _vm.clickText, copy: _vm.handleCopy },
      }),
      _c("span", [
        _vm._v("  "),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showCopy,
              expression: "showCopy",
            },
          ],
          staticClass: "iconfont icon-fuzhi",
          on: { click: _vm.copy },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }