<template>
  <div class="law-detail-box">
    <fb-header slot="header" :customer="true" v-if="showTitleBar" :title="Title || title" :isdet="true" @clickTitle="clickTitle" fixed>
      <fb-button slot="left" icon="back" size="small" type="primary" @click="clickback" v-if="!this.$route.query || !this.$route.query.docid"> </fb-button>
      <!-- 菜单 -->
      <span class="copy" slot="copy" style="flex: 1">
        <span class="pattern" @click="changePattern">{{ pattern === '简洁' ? '完整' : '简洁' }}</span>
        <i class="iconfont icon-fuzhi" @click="copyTitle" v-show="showCopy"></i>
      </span>
      <fb-button slot="right" icon="menu" size="small" type="primary" @click.stop.capture="liste">
        <template name="right">
          <div class="menu" v-if="collect">
            <div @click="download">分享</div>
            <div @click="collection" v-if="!this.$route.query || !this.$route.query.lawid">收藏</div>
            <div @click="download2" v-if="ua !== 'fb-iOS-Webview' && (!this.$route.query || !this.$route.query.lawid)">下载</div>
          </div>
        </template>
      </fb-button>
    </fb-header>
    <div id="lawDetail" ref="container" class="highlight-area" :class="pattern === '完整' ? '' : 'law-detail-scroll'" @scroll="taggleBottomScroll">
      <div v-show="!skydriveif">
        <!-- 完整模式 -->
        <div ref="lawDetail" class="law-content-wrap" v-if="pattern === '完整'">
          <fb-loading :loading="loading" />
          <!-- 内容 -->
          <law-content v-if="lawDetail && lawDetail.content" :lawContent="lawDetail" :catlog="catlog" @collect="collectContent" :title="this.title || this.Title" :lawid="id" id="contena" />
          <div v-else class="loading">
            <van-list v-model="loading" :finished="finished">
              <div class="container" ref="detailContainer"></div>
            </van-list>
          </div>
          <!-- 详情页右下侧图标 -->
          <div class="icon-icon">
            <div
              class="iconfonts"
              v-show="reportCount"
              @click="handleCollect"
              ref="collect"
              :style="{
                background: isCollect ? '#f4a83b' : '',
                position: 'relative',
              }"
            >
              <transition name="slide-fade">
                <span class="badge-box" v-show="showAdd"> +1 </span>
              </transition>
              <transition name="slide-fade">
                <span class="badge-box" v-show="showReduce"> -1 </span>
              </transition>
              <span>集</span>
              <span class="count">{{ this.reportCount }}</span>
            </div>
            <div class="iconfonts zhiding" @click="clickZhiding" ref="zhiding">
              <span class="iconfont icon-zhiding"></span>
            </div>
            <div class="iconfonts mulu" @click="clickShow">
              <span class="iconfont icon-zhediexiaoguo1"></span>
            </div>
            <!-- 新增加搜索按钮 待开发-->
            <div class="iconfonts fanhui" @click="clickShowSearchBar" v-if="!searchWordsShow">
              <span class="iconfont icon-sousuo"></span>
            </div>
            <div class="iconfonts fanhui" v-else style="visibility: hidden">
              <span class="iconfont icon-sousuo"></span>
            </div>
          </div>
          <div class="search-words" v-show="searchWordsShow">
            <div class="words">
              <div v-for="(item, i) in wordsList" :key="i">{{ item }}<i class="iconfont icon-clear" @click="deleteWord(i)"></i></div>
            </div>
            <div class="input">
              <span style="color: #409eff; margin-right: 10px" @click="closeSearch">退出</span>
              <div style="flex: 1">
                <van-field v-model="value" placeholder="请输入关键词" @input="handleChange" @keyup.native="hanldeKeySpace" @keyup.enter="hanldeKeyEnter" @blur="handleBlur" ref="searchKeyWordInput" />
              </div>
              <div style="margin: 0 5px">
                <div style="color: #409eff">{{ currentPos }}</div>
                <div style="color: #ccc">{{ times }}</div>
              </div>
              <van-icon name="arrow-down" @click="optNext" />
              <van-icon name="arrow-up" @click="optPrev" />
            </div>
          </div>
          <!-- 目录 -->
          <van-popup v-model="show" position="right" class="lawMenu">
            <van-tabs v-model="active" color="#569af0">
              <van-tab title="目录">
                <law-menu :lawContent="lawDetail" @jumpTo="jumpToTitle" />
              </van-tab>
              <van-tab title="相关内容">
                <law-correlation :lawContent="lawDetail" />
              </van-tab>
            </van-tabs>
          </van-popup>
        </div>
        <!-- 简洁模式 -->
        <div ref="lawDetail" class="law-content-wrap" v-else-if="pattern === '简洁'">
          <fb-loading :loading="loading" />
          <!-- 内容 -->
          <law-content-concise v-if="lawDetail && lawDetail.content" :lawContent="lawDetail" :catlog="catlog" @collect="collectContent" :title="this.title || this.Title" :lawid="id" id="contena" />
          <div v-else class="loading">
            <van-list v-model="loading" :finished="finished">
              <div class="container" ref="detailContainer"></div>
            </van-list>
          </div>
          <!-- 详情页右下侧图标 -->
          <div class="icon-icon">
            <div class="iconfonts zhiding" @click="clickZhiding" ref="zhiding">
              <span class="iconfont icon-zhiding"></span>
            </div>
            <div class="iconfonts mulu" @click="clickShow">
              <span class="iconfont icon-zhediexiaoguo1"></span>
            </div>
          </div>
          <!-- 目录 -->
          <van-popup v-model="show" position="right" class="lawMenu">
            <van-tabs v-model="active" color="#569af0">
              <van-tab title="目录">
                <law-menu :lawContent="lawDetail" @jumpTo="jumpToTitle" />
              </van-tab>
            </van-tabs>
          </van-popup>
        </div>
        <div v-if="haha" class="skyDrive">
          <skyDrive :listid="id" :Type="'lawid'" @CancelCollect="close" :listname="this.title || this.Title" @getId="getId"></skyDrive>
        </div>
      </div>
      <div v-if="skydriveif" class="sky-drive-if">
        <sky-drive :skydriveif="skydriveif" @close="closesky"></sky-drive>
      </div>
      <div v-if="showReport" class="collect-report">
        <searchReport @close="closeReport" from="law" />
      </div>
    </div>
    <van-popup class="guidance-van-popup" v-model="guidanceShow" :close-on-click-overlay="false">
      <guidance @close="closeGuidancePopup" type="web" animationId="2001" />
    </van-popup>
  </div>
</template>
<script>
import { caseBase } from '~api-config'
import lawContent from './pages/lawContent.vue'
import lawMenu from './pages/lawMenu.vue'
import lawCorrelation from './pages/lawCorrelation.vue'
import lawContentConcise from './pages/concise/law-content-concise.vue'
import { tools } from '../../compoments/tool.js'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import taggleBottomMixin from '../../taggleBottomMixin'
import searchWord from '../../compoments/searchword'
import guidance from './pages/guidance'
export default {
  name: 'law-detail',
  props: ['title', 'id', 'catlog', 'words'],
  mixins: [taggleBottomMixin],
  components: {
    skyDrive: () => import('@/pages/skyDrive/index'),
    searchReport: () => import('@/pages/transform/searchReport'),
    lawContent,
    lawMenu,
    lawCorrelation,
    lawContentConcise,
    guidance,
  },
  watch: {
    contentReports: {
      deep: true,
      handler() {
        this.contentArr = JSON.parse(JSON.stringify(this.contentReports)) || []
      },
    },
    reportCount(nv, ov) {
      this.lastCount = ov
      if (nv === ov + 1) {
        this.showAdd = true
        setTimeout(() => {
          this.showAdd = false
        }, 600)
      } else if (nv === ov - 1) {
        this.showReduce = true
        setTimeout(() => {
          this.showReduce = false
        }, 600)
      }
      // console.log(nv, ov, '监听count数量')
    },
    showmenu() {
      this.show = false
    },
    // 监听输入框
    inputlawdata(value) {
      console.log(value)
      // 输入后实时调用原生方法实现样式高亮--待开发
      try {
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // 安卓环境
          // eslint-disable-next-line no-undef
          AND2JS.setHighlightText(value, false)
        } else if (u === 'fb-iOS-Webview') {
        } else {
          this.$toast('非原生环境')
        }
      } catch (err) {}
    },
    $route(to, from) {
      console.log(from.path) // 从哪来
      console.log(to.path) // 到哪去
    },
  },
  computed: {
    ...mapState('search', ['showmenu']),
    ...mapGetters('searchReport', ['reportCount']),
    ...mapGetters('user', ['userId']),
    ...mapState('searchReport', ['judgeReports', 'lawReports', 'criterionReports', 'contentReports', 'knowledgeReports']),

    ua() {
      return navigator.userAgent
    },
  },
  data() {
    return {
      guidanceShow: false, // 简洁，完整指导弹框
      pattern: '简洁', // 模式， 简洁和完整
      contentArr: [],
      showCopy: false, // 是否显示复制按钮
      showAdd: false, // 是否显示+1
      showReduce: false, // 是否显示-1
      lastCount: 0, // 检索报告的数值
      numid: 0,
      preview: false,
      collect: false,
      Title: '',
      haha: false,
      showReport: false,
      autofocus: true,
      showTitleBar: true,
      showSearchBar: false,
      inputlawdata: '',
      Ci: '',
      pageInfo: {
        id: '',
      },
      lawDetail: {
        content: [],
        catlog_data: [],
        meditate_data: [],
        more_info: [],
      },
      show: false,
      active: 0,
      isCollect: false,
      loading: false,
      skydriveif: false,
      finished: false,
      searchOpt: null,
      wordsList: [],
      opt: {
        prev: function () {},
        next: function () {},
        des: null,
      },
      value: '',
      searchWordsShow: false,
      times: 0,
      currentPos: 0,
    }
  },
  created() {
    this.getAnimation()
    this.hiddenBottom()
    if (this.$route.query && this.$route.query.lawid) {
      this.id = this.$route.query.lawid
    }
    if (this.id) {
      this.getLawConent()
    }
    this.setUserCurrentObj(this)
    // console.log(this.catlog + '//' + this.id + '//' + this.title)
    window.showTitleBar = function () {
      this.showTitleBar = true
    }.bind(this)
  },
  mounted() {
    this.bodyDom = document.body.offsetWidth
    // console.log(this.bodyDom)
    tools.changeColor()
    this.$refs.zhiding.style.display = 'none'
    // this.$refs.container.addEventListener('scroll', this.handleScroll)
    window.addEventListener('scroll', this.handleScroll, true)
    // document.body.addEventListener('scroll', this.testlawd)
    // setInterval(() => {
    //   console.log(this.$refs.container.scrollTop)
    // }, 500)
    this.contentArr = JSON.parse(JSON.stringify(this.contentReports)) || []
  },
  destroyed() {
    console.log('移除事件')
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    closeGuidancePopup() {
      this.guidanceShow = false
    },
    // 获取是否要展示提示框
    getAnimation() {
      this.$axios.get(`${this.$base}/document/animation/select?type=web&animationId=2001`).then((res) => {
        if (res.data.code === '200') {
          if (res.data.data.animationNum > 3) {
            this.guidanceShow = false
          } else {
            this.guidanceShow = true
          }
        }
      })
    },
    // 切换简洁和完整模式
    changePattern() {
      this.pattern = this.pattern === '简洁' ? '完整' : '简洁'
    },
    clickTitle() {
      this.showCopy = !this.showCopy
    },
    copyTitle() {
      // console.log(this.Title)
      const str = this.Title || this.title
      const transfer = document.createElement('textarea')
      document.body.appendChild(transfer)
      transfer.value = str // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中',
      })
      setTimeout(() => {
        this.showCopy = false
      }, 1000)
    },
    closeReport() {
      this.showReport = false
    },
    // 处理content的数据
    handleContent(content) {
      content.map((item1) => {
        if (item1.type === '条') {
          if (item1.addition_data && item1.addition_data.length > 0) {
            var flag = false
            item1.addition_data.forEach((item2) => {
              if (item2.values && item2.values.length > 0) {
                flag = true
              }
            })
            if (flag) {
              item1.additionShow = true
            }
            item1.addition_data.forEach((item2) => {
              item2.show = true
              if (item2.values && item2.values.length > 0) {
                item2.values.forEach((item3) => {
                  if (item3.child && item3.child.length > 0) {
                    item3.child.forEach((item4) => {
                      // 实际像素（this.bodyDom的值） - padding / 37.5（缩放比例） * 实际像素 / 10
                      // 实际像素 / 10 指的是根的font-size的大小
                      const x = this.bodyDom / 10 / 37.5
                      const width = this.bodyDom - 58 * x
                      // 用宽度除以每个字的size（14），获得每行多少个字，超过5行显示省略号
                      const num = parseInt(width / (14 * x)) * 5
                      if (item4.text && item4.text.length > num) {
                        item4.textMore = true
                      }
                      item4.title_copy = false
                      item4.text_copy = false
                    })
                  }
                  item3.law_title_copy = false
                  item3.case_name_copy = false
                })
              }
            })
          }
          if (item1.clip_data && item1.clip_data.length > 0) {
            var flag1 = false
            item1.clip_data.forEach((item2) => {
              if (item2.values && item2.values.length > 0) {
                flag1 = true
              }
            })
            if (flag1) {
              item1.clipDataShow = true
            } else {
              item1.clipDataShow = false
            }
          }
          return item1
        } else {
          if (item1.values && item1.values.length > 0) {
            return this.handleContent(item1.values)
          } else {
            return item1
          }
        }
      })
      return content
    },
    // 获取法律数据
    getLawConent() {
      this.loading = true
      let words = ''
      if (this.words) {
        this.words.forEach((item, index) => {
          words += '&word=' + item
        })
      }
      words = words.slice(0, 26)
      this.$axios
        .get(`${this.$pydataBase}/api/nvi/law/detail?law_id=${this.id}${words}`)
        .then((res) => {
          this.loading = false
          this.Title = res.data.result.case_name
          // 处理灰色区域的数据内容
          res.data.result.content = this.handleContent(res.data.result.content)
          this.lawDetail = res.data.result
          setTimeout(() => {
            this.$emit('highlight')
          }, 500)
          if (this.title) {
            this.jumpToTitle(this.title)
          }
          this.url = window.document.location.href
          if (this.url.indexOf('#') > -1) {
            // 解除绑定
            this.url = this.url.slice(0, this.url.indexOf('#'))
            window.document.location.href = this.url
          }
          this.searchOpt = searchWord(this.$refs.lawDetail, `${this.url}`)
          // this.$nextTick(() => {
          //   // 跳转到对应的条目
          //   if (this.$route.query.aName) {
          //     const aDom = document.createElement('a')
          //     aDom.href = '#' + this.$route.query.aName
          //     document.body.appendChild(aDom)
          //     aDom.click()
          //     document.body.removeChild(aDom)
          //   }
          // })
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
          this.$notify('请求超时或错误 请重试')
        })
    },
    // 跳转到指定law-title（第n条）附近
    jumpToTitle(title) {
      console.log('跳转到指定law-title', title)
      this.$nextTick(() => {
        const ctx = document.getElementById(title)
        if (ctx) {
          ctx.scrollIntoView()
        } else {
          this.$notify('未找到可以跳转的法条')
        }
      })
    },
    download() {
      const ua = navigator.userAgent
      const url = `${window.location.origin}/transform/searchKeepAlive/lawDetail?lawid=${this.id}`
      console.log('分享的地址', url)
      if (ua === 'fb-Android-Webview') {
        // eslint-disable-next-line no-undef
        AND2JS.shareCaseFileWithDes(this.title, '律呗法律', window.encodeURI(url))
      } else if (ua === 'fb-iOS-Webview') {
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: this.title,
            url: window.encodeURI(url),
          })
        )
      } else if (ua === 'fb-flutter-Webview') {
        try {
          window.shareCaseFile.postMessage(
            JSON.stringify({
              filename: this.title,
              url: window.encodeURI(url),
            })
          )
        } catch (error) {
          console.log('shareCaseFile.postMessage', error)
        }
      } else {
        const text = `${window.location.origin}/transform/searchKeepAlive/lawDetail?lawid=${this.id}`
        const transfer = document.createElement('input')
        document.body.appendChild(transfer)
        transfer.value = text // 这里表示想要复制的内容
        transfer.select()
        if (document.execCommand('copy')) {
          document.execCommand('copy')
        }
        transfer.blur()
        document.body.removeChild(transfer)
        this.$notify({
          type: 'success',
          message: '已将信息复制到粘贴板，长按粘贴。',
        })
      }
      // this.$axios
      //   .get(
      //     `${caseBase}/document/user/${
      //       this.$store.state.user.userInfo.id
      //     }/legal/precedent/${this.id}/file`
      //   )
      //   .then(res => {
      //     console.log('this.title===>', this.title)
      //     const ua = navigator['userAgent']
      //     const url = `${
      //       window.location.origin
      //     }/transform/searchKeepAlive/lawDetail?lawid=${this.id}`
      //     console.log('分享的地址', url)
      //     if (ua === 'fb-Android-Webview') {
      //       // eslint-disable-next-line no-undef
      //       AND2JS.shareCaseFile(this.title, '律呗法律', window.encodeURI(url))
      //     } else if (ua === 'fb-iOS-Webview') {
      //       window.webkit.messageHandlers.shareCaseFile.postMessage(
      //         JSON.stringify({
      //           filename: this.title,
      //           url: window.encodeURI(url)
      //         })
      //       )
      //     } else {
      //       const text = `${
      //         window.location.origin
      //       }/transform/searchKeepAlive/lawDetail?lawid=${this.id}`
      //       const transfer = document.createElement('input')
      //       document.body.appendChild(transfer)
      //       transfer.value = text // 这里表示想要复制的内容
      //       transfer.select()
      //       if (document.execCommand('copy')) {
      //         document.execCommand('copy')
      //       }
      //       transfer.blur()
      //       document.body.removeChild(transfer)
      //       this.$notify({
      //         type: 'success',
      //         message: '已将信息复制到粘贴板，长按粘贴。'
      //       })
      //     }
      //   })
      //   .catch(err => {
      //     this.$notify({
      //       duration: 1000,
      //       message: '分享失败'
      //     })
      //     setTimeout(() => {
      //       document.querySelector('.van-notify').style.zIndex = '10000000'
      //     }, 100)
      //     Promise.reject(err)
      //   })
    },

    // 格式化时间 年-月-日 时：分：秒
    formatDate(fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    download2() {
      const time = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
      // console.log('时间戳:', time)
      const url = `${caseBase}/document/legal/precedent/${this.id}/file`
      console.log(url, '====>>> 接口返参12')
      const ua = navigator.userAgent
      if (ua === 'fb-Android-Webview') {
        // eslint-disable-next-line no-undef
        AND2JS.showFilePreview(url)
      } else if (ua === 'fb-iOS-Webview') {
        window.webkit.messageHandlers.fbDownloadFile.postMessage(url)
      } else if (ua === 'fb-flutter-Webview') {
        try {
          // 之前的方法
          // window.fbDownloadFile.postMessage(url)
          // 新改的方法
          window.showFilePreview.postMessage(
            JSON.stringify({
              filePath: url,
              fileName: time,
            })
          )
        } catch (error) {
          console.log('fbDownloadFile.postMessage', error)
        }
      } else {
        window.location = url
      }
    },
    clearpl() {
      this.preview = false
    },
    getId(id) {
      this.notify('收藏成功,点击查看收藏').addEventListener(() => {
        this.$notify.clear()
        const dom = document.querySelector('.menuOfDetailInCaseInAna')
        if (dom) {
          dom.style.display = 'none'
        }
        this.skydriveif = true
      })
    },
    closesky() {
      this.skydriveif = false
      const dom = document.querySelector('.menuOfDetailInCaseInAna')
      if (dom) {
        dom.style.display = 'none'
      }
    },
    collection() {
      this.haha = true
    },
    notify(message, color = '#07c160') {
      this.$notify({
        message: message,
        background: color,
        className: 'TOSAVELIST',
      })
      return {
        addEventListener(callback) {
          setTimeout(() => {
            document.querySelector('.TOSAVELIST').onclick = callback
          })
        },
      }
    },
    liste() {
      this.collect = !this.collect
      if (this.collect) {
        const fn = () => {
          this.collect = false
          document.body.removeEventListener('click', fn)
        }
        document.body.addEventListener('click', fn, false)
      }
    },
    ...mapMutations('user', ['setUserCurrentObj']),
    ...mapActions('searchReport', ['setStampReport']),
    ...mapMutations('searchReport', ['SET_CLEAR_CHECK', 'SET_CONTENT_REPORT']),
    // 跳到指定页面位置
    // gocontent () {
    //   Array.from(document.querySelectorAll('#contena .lawTitle .name')).forEach(ellist => {
    //     if (ellist.innerText === of) {
    //       ellist.parentElement.getAttribute('id')
    //       document.querySelector('#' + ellist.getAttribute('id')).scrollIntoView({ block: 'center' })
    //     }
    //   })
    // },
    // testlawd() {
    //   console.log('aaa')
    // },
    close(flag) {
      this.haha = false
      if (flag) {
        setTimeout(() => {
          document.querySelector('.van-notify').style.zIndex = '10000000'
        }, 100)
      } else {
        this.$notify({
          background: 'rgb(25, 137, 250)',
          duration: 1000,
          message: '已取消收藏',
        })
        setTimeout(() => {
          document.querySelector('.van-notify').style.zIndex = '10000000'
        }, 100)
      }
    },
    closeinput() {
      if (this.showSearchBar) {
        this.showSearchBar = false
      }
    },
    // 搜索框结果操作方法
    togo() {},
    toup() {},
    // 搜索聚焦，失焦
    focu() {
      this.autofocus = true
    },
    gogo() {
      this.showSearchBar = false
    },
    // 点击返回
    clickback() {
      history.replaceState(null, null, ' ')
      if (Object.keys(this.$route.query).length === 0) {
        console.log('显示底部导航栏1')
        this.$emit('changeflag', false)
        const ua = navigator.userAgent
        if (ua === 'fb-Android-Webview') {
          // eslint-disable-next-line no-undef
          AND2JS.showBottomTab('display')
        }
      } else {
        if (this.$route.params.flag) {
          console.log('显示底部导航栏2')
          this.$router.go(-1)
          return
        }
        try {
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            console.log('显示底部导航栏3')
            // eslint-disable-next-line
            AND2JS.showBottomTab('display')
            // eslint-disable-next-line
            AND2JS.back()
          } else if (ua === 'fb-flutter-Webview') {
            // eslint-disable-next-line
            window.back.postMessage('{}')
          } else if (ua === 'fb-iOS-Webview') {
            console.log('显示底部导航栏4')
            // eslint-disable-next-line
            window.webkit.messageHandlers.back.postMessage({})
          }
        } catch (err) {
          window.parent.postMessage('finish', window.document.location.origin)
          console.log('back.postMessage', err)
        }
      }
    },
    // 调用原生弹出搜索框和软键盘
    clickShowSearchBar() {
      this.searchWordsShow = !this.searchWordsShow
      setTimeout(() => {
        this.$refs.searchKeyWordInput.focus()
      }, 100)
    },
    blur(value) {
      if (value !== '') {
        if (this.opt.Destruction) {
          this.opt.Destruction()
          this.opt.Destruction = null
        }
        this.wordsList.push(value)
        this.value = ''
        this.opt = this.searchOpt.findWords(this.wordsList, this.$refs.lawDetail, this.value)
        this.times = this.searchOpt.times
        this.currentPos = this.searchOpt.currentPos
      }
    },
    deleteWord(i) {
      if (this.opt.Destruction) {
        this.opt.Destruction()
        this.opt.Destruction = null
      }
      this.wordsList.splice(i, 1)
      this.opt = this.searchOpt.findWords(this.wordsList, this.$refs.lawDetail, this.value)
      this.times = this.searchOpt.times
      this.currentPos = this.searchOpt.currentPos
      if (this.wordsList.length === 0) {
        if (this.opt.Destruction) {
          this.opt.Destruction()
          this.opt.Destruction = null
        }
      }
    },
    closeSearch() {
      this.times = 0
      this.currentPos = 0
      this.wordsList = []
      this.searchWordsShow = false
      if (this.opt.Destruction) {
        this.opt.Destruction()
        this.opt.Destruction = null
      }
    },
    handleChange(value) {
      if (this.value.trim().length === 0) {
        this.value = ''
      }
      if (this.opt.Destruction) {
        this.opt.Destruction()
        this.opt.Destruction = null
      }
      this.opt = this.searchOpt.findWords(this.wordsList, this.$refs.lawDetail, this.value)
      this.times = this.searchOpt.times
      this.currentPos = this.searchOpt.currentPos
    },
    handleBlur() {
      setTimeout(() => {
        this.hiddenBottom()
      }, 500)
    },
    hanldeKeySpace(e) {
      if (this.value.trim() !== '' && this.value.length && this.value.indexOf(' ') > -1) {
        var key = this.value.trim()
        this.blur(key)
        this.$nextTick(() => {
          this.value = ''
        })
      }
    },
    optPrev() {
      this.openAdditionData(this.lawDetail.content)
      if (this.value !== '') {
        this.blur(this.value.trim())
      }
      this.opt.prev()
      this.currentPos = this.searchOpt.currentPos
    },
    optNext() {
      this.openAdditionData(this.lawDetail.content)
      if (this.value !== '') {
        this.blur(this.value.trim())
      }
      this.opt.next()
      this.currentPos = this.searchOpt.currentPos
    },
    // 展开全部的灰色区域的内容
    openAdditionData(content) {
      if (content.length > 0) {
        content.forEach((item) => {
          if (item.additionShow !== undefined) {
            item.additionShow = true
          }
          if (item.addition_data && item.addition_data.length > 0) {
            item.addition_data.forEach((item1) => {
              item1.show = true
              if (item1.values && item1.values.length > 0) {
                item1.values.forEach((item2) => {
                  if (item2.child && item2.child.length > 0) {
                    item2.child.forEach((item3) => {
                      if (item3.textMore !== undefined) {
                        item3.textMore = false
                      }
                    })
                  }
                })
              }
            })
          }
          if (item.textMore !== undefined) {
            item.textMore = false
          }
          if (item.values) {
            this.openAdditionData(item.values)
          }
        })
      }
    },
    hanldeKeyEnter(e) {
      if (this.value === '') {
        this.opt.next()
        this.currentPos = this.searchOpt.currentPos
      } else {
        this.blur(this.value.trim())
      }
    },
    clickShow() {
      this.show = true
    },
    collectContent(payload) {
      // const { collection, textArr } = payload
      const arr = [...payload.collection]
      const textArr = [...payload.textArr]
      const reportObj = {
        id: this.id,
        isLaw: true,
        title: this.Title,
        arr,
        textArr,
      }
      if (this.contentArr && this.contentArr.length) {
        const index = this.contentArr.findIndex((item) => item.id === reportObj.id)
        if (index !== -1) {
          this.contentArr[index] = reportObj
        } else {
          this.contentArr.push(reportObj)
        }
      } else if (this.contentArr && this.contentArr.length === 0) {
        this.contentArr.push(reportObj)
      }
      // this.SET_CONTENT_REPORT(JSON.parse(JSON.stringify(this.contentArr)))
      console.log(this.contentArr, '<<------contentArr-------')
      const filterArr = this.contentArr.filter((item) => {
        return item.arr.length + item.textArr.length > 0
      })
      this.setStampReport({
        userId: this.userId,
        type: 'contentReports',
        reportData: [...JSON.parse(JSON.stringify(filterArr))],
      })
    },
    handleCollect() {
      this.isCollect = !this.isCollect
      this.SET_CLEAR_CHECK(false)
      this.showReport = true
      // this.$router.push('/transform/searchReport')
    },
    clickZhiding() {
      // console.log(this.$refs.container.scrollTop)
      // this.$refs.container.scrollTop = 1000
      const Interval = setInterval(() => {
        if (this.$refs.container.scrollTop > 0) {
          this.$refs.container.scrollTop = this.$refs.container.scrollTop - this.$refs.container.scrollTop / 5
        } else {
          clearInterval(Interval)
        }
      }, 1)
    },
    handleScroll(e) {
      // console.log('滑动了')
      this.taggleBottomScroll(e)
      if (e.target.scrollTop < 100) {
        this.$refs.zhiding.style.display = 'none'
      } else {
        this.$refs.zhiding.style.display = 'block'
      }
    },
  },
}
</script>
<style scoped lang="stylus">
.law-detail-box /deep/ .fb-header-title
    font-size 14px !important
.law-detail-box /deep/ .pattern
    color: #3399FF
    font-size 14px
    margin-right 10px
.law-detail-box /deep/ .guidance-van-popup
  width 100%
  height 100%
  background none
.law-detail-box
  padding-top 44px
  box-sizing border-box
#lawDetail
  // height 100vh
  height: calc(100vh - 44px);
  position: relative;
  overflow-y auto
  overflow-x hidden
  .menu
    width 101px
    box-shadow 0px 0px 7px 3px rgba(240, 240, 240, 1)
    background #ffffff
    border-radius 10px
    position absolute
    right 0px
    top calc(100% + 5px)
    div
      height 43px
      width 100%
      line-height 43px
  & .law-content-wrap
    position absolute
    top 0px
    width 100%
    height 100vh
    // overflow auto
.search-words
  width 100%
  position fixed
  background-color white
  border solid #ccc 1px
  bottom 0px
  left 0px
  z-index 999
  padding 10px
  & .words
    display flex
    align-items center
    flex-wrap wrap
    & > div
      background #ccc
      color #FFF
      padding 0 3px
      margin-right 5px
      margin-bottom 5px
  & .input
    display flex
    align-items center
    & .van-icon
      font-size 30px
      margin-left 10px
    & .van-cell
      padding 0
.jianpan
  width 100%
  position fixed
  top 93.5%
  z-index 10525
  height 48px
  display flex
  align-items center
  border 1px solid red
  background rgb(255, 255, 255)
  .right
    font-size 15px
    margin 0 10%
    // .togo .toup
    // padding-right 3%
.lawMenu
  height 100%
  margin 0
  padding 0
.icon-icon
  position fixed
  bottom 55px
  right 20px
  z-index 1000
.iconfonts
  width 45px
  height 45px
  background-color #b6b6b6
  box-shadow 0px 0px 2px 0px rgba(204, 204, 204, 0.56)
  border-radius 50%
  text-align center
  line-height 45px
  margin-top 10px
  span
    color #ffffff
    font-size 24px !important
& .badge-box
    position absolute
    top -10px
    right 0
    text-align right
    color red !important
    z-index 999
    font-weight bold !important
    font-size 14px !important
& span.count
    font-size 16px !important
    position absolute
    color red
    line-height 16px
    right 3px
    top 3px
.mulu
  box-shadow 0px 0px 2px 0px rgba(26, 130, 227, 0.56)
.fanhui
  box-shadow 0px 0px 2px 0px rgba(239, 119, 31, 0.56)
#contena
  padding 16px 14px
.skyDrive
  position fixed
  top 0px
  background #ffffff
  z-index 10000000
  width 100%
  left 0px
  height 100%
  overflow hidden
.pre
  position fixed
  top 0px
  background #ffffff
  z-index 10000000
  width 100%
  left 0px
  height 100%
  overflow hidden
.TOSAVELIST
  z-index 1000000001 !important
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translate(-30px, -10px) scale(3);
  opacity: 0;
}
</style>
<style scoped>
.sky-drive-if {
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 100;
  position: fixed;
}
.collect-report {
  width: 100%;
  height: 100%;
  background: #fff;
  top: 0;
  overflow-y: scroll;
  z-index: 1000000001 !important;
  position: fixed;
}
.icon-fuzhi {
  font-size: 14px !important;
  color: #ccc;
  cursor: pointer;
  opacity: 0.5;
}

.law-detail-scroll::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0);
  border-left: 1px solid rgba(0, 0, 0, 0);
}
.law-detail-scroll::-webkit-scrollbar {
  width: 10px;
  height: 13px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.law-detail-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  min-height: 28px;
}
.law-detail-scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
</style>
