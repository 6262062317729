<template>
  <div style="min-width:264px">
    <ul v-for="(item, index) of lawContent.catlog_data" :key="index">
      <Tree :data="item" />
    </ul>
  </div>
</template>
<script>
import Tree from './tree'
export default {
  props: ['lawContent'],
  components: {
    Tree
  }
}
</script>
