export const tools = {
  changeColor: () => {
    setTimeout(() => {
      Array.prototype.slice
        .call(document.querySelectorAll('em'))
        .forEach(item => {
          item.style.color = 'red'
          item.style.fontWeight = '600'
        })
    })
  },
  scroll (element, callback) {
    let num = 1
    if (element.scrollHeight === element.offsetHeight) {
      num++
      const flag = callback(num)
      num = flag === false ? num - 1 : num
    }
    element.addEventListener('scroll', scroll, false)
    function scroll (e) {
      if (this.offsetHeight + this.scrollTop === this.scrollHeight) {
        num++
        const flag = callback(num)
        num = flag === false ? num - 1 : num
      }
    }
    return function () {
      element.removeEventListener('scroll', scroll)
    }
  }
}
