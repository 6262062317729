<template>
  <div class="text-item">
    <div class="text">
      <span @click="clickText" v-html="str" @copy="handleCopy"></span>
      <span
        >&nbsp;&nbsp;<i
          class="iconfont icon-fuzhi"
          @click="copy"
          v-show="showCopy"
        ></i
      ></span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'textItem',
  props: [
    'str',
    'index',
    'checkedAll',
    'checkedParent',
    'title',
    'lawTitle',
    'textArr',
    'additionShow',
    'isAddition'
  ],
  data() {
    return {
      checked: false,
      showCopy: false,
      timeOutEvent: null,
      longPress: false
    }
  },
  computed: {
    ...mapState('searchReport', ['clearCheck'])
  },
  methods: {
    setAdditionShow(flag) {
      this.$emit('update:additionShow', flag)
    },
    handleCopy(e) {
      console.log(e)
      const clipboardData = e.clipboardData || window.clipboardData
      // 如果 未复制或者未剪切，直接 return
      if (!clipboardData) return
      // Selection 对象 表示用户选择的文本范围或光标的当前位置。
      // 声明一个变量接收 -- 用户输入的剪切或者复制的文本转化为字符串
      const text = window.getSelection().toString()
      console.log('text------------>>', text)
      if (text) {
        // 如果文本存在，首先取消默认行为
        e.preventDefault()
        // 通过调用 clipboardData 对象的 setData(format,data) 方法，设置相关文本
        // format 一个 DOMString 类型 表示要添加到 drag object 的拖动数据的类型
        // data 一个 DOMString 表示要添加到 drag object 的数据
        clipboardData.setData('text/plain', text)
        this.$emit('copy', text)
      }
    },
    // gotouchstart() {
    //   this.longPress = false
    //   clearTimeout(this.timeOutEvent) // 清除定时器
    //   this.timeOutEvent = setTimeout(() => {
    //     // 执行长按要执行的内容
    //     console.log('执行长按要执行的内容')
    //     this.longPress = true
    //   }, 600) // 这里设置定时
    // },
    // // 手释放，如果在500毫秒内就释放，则取消长按事件，此时可以执行onclick应该执行的事件
    // gotouchend() {
    //   clearTimeout(this.timeOutEvent)
    //   if (this.timeOutEvent && !this.longPress) {
    //     // 这里写要执行的内容（尤如onclick事件）
    //     console.log('这里写要执行的内容（尤如onclick事件）')
    //   }
    // },
    // // 如果手指有移动，则取消所有事件，此时说明用户只是要移动而不是长按
    // gotouchmove() {
    //   clearTimeout(this.timeOutEvent) // 清除定时器
    //   this.timeOutEvent = null
    //   this.longPress = false
    //   console.log(
    //     '如果手指有移动，则取消所有事件，此时说明用户只是要移动而不是长按 '
    //   )
    // },
    clickText(e) {
      // console.log('clickText测试功能')
      // const text = 'clickText测试功能'
      // this.$emit('copy', text)
      if (e.target && e.target.localName === 'em') {
        this.$emit('addClick', e)
      } else {
        this.showCopy = !this.showCopy
      }
    },
    copy() {
      const str = this.lawTitle + '\n' + this.title + '\n' + this.str
      console.log(str, 'copy title')
      const transfer = document.createElement('textarea')
      document.body.appendChild(transfer)
      transfer.value = str // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中'
      })
      setTimeout(() => {
        this.showCopy = false
      }, 1000)
    },
    selectText() {
      this.checked = !this.checked
      this.$emit('select', {
        index: this.index,
        flag: this.checked,
        text: this.str
      })
    }
  },
  mounted() {
    if (this.textArr.length) {
      console.log('textArr', this.textArr)
      this.textArr.forEach(item => {
        if (item.index === this.index) {
          this.checked = true
        } else {
          this.checked = false
        }
      })
    }
  },
  watch: {
    clearCheck(val) {
      if (val) {
        this.checked = false
      }
    },
    checkedParent(val) {
      // this.checked = this.checkedAll
      if (!val && !this.checkedAll) {
        // 取消全选
        this.checked = false
      } else if (val && this.checkedAll) {
        // 全选
        this.checked = true
      }
      // console.log(this.checkedParent, this.checkedAll, 'watch')
    }
  }
}
</script>

<style scope lang="stylus">
.text-item
  position relative
  .check
    position absolute
    left 0
    top 0
    display flex
    z-index 99
    justify-content center
    align-items center
    width 30px
    height 30px
    .van-checkbox__icon
      .van-icon
        font-size 8px
        line-height 30px
  .text
    line-height 30px
    text-indent 30px
    & .icon-dakai-01
      font-size 20px
      color #FF9900
    & .icon-dakai-011
      font-size 20px
      color #FF9900
  & .iconfont
    font-size 12px !important
    color #ccc
    opacity 0.5
    cursor pointer
      // color #1989fa
</style>
