var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lawMenu" }, [
    _c("div", { staticClass: "title" }, [
      _c(
        "a",
        {
          attrs: { href: "#" + _vm.fmtName(_vm.data.name || _vm.data) },
          on: {
            click: function ($event) {
              _vm.setShowMenu(+new Date())
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "left",
              domProps: {
                innerHTML: _vm._s(
                  _vm.data.name
                    ? _vm.data.name +
                        (_vm.data.sub_name
                          ? "<br/>(" + _vm.data.sub_name + ")"
                          : "")
                    : _vm.data
                ),
              },
            },
            [_vm._v(_vm._s(_vm.data.name || _vm.data))]
          ),
        ]
      ),
      _vm.right
        ? _c("span", {
            staticClass: "iconfont icon-child-off",
            on: {
              click: function ($event) {
                return _vm.open()
              },
            },
          })
        : _vm._e(),
    ]),
    _vm.showopen
      ? _c(
          "div",
          _vm._l(_vm.data.value, function (item, index) {
            return _c("tree-item", { key: index, attrs: { data: item } })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }