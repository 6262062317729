var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "guidance" }, [
    _c("div", { staticClass: "img-box" }, [
      _vm._v(" 右上角点击完整，换到完整模式，查看关联法条等数据 "),
    ]),
    _c(
      "div",
      { staticClass: "btn-box" },
      [
        _c(
          "van-button",
          { attrs: { type: "info", size: "normal" }, on: { click: _vm.sure } },
          [_vm._v("知道了")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }