<template>
  <div class="law-item-comp">
    <div class="law-item-comp__title">
      <!-- 第一级别 -->
      <span
        :class="{ name: true, tiao: propData.type === '条' }"
        :id="fmtName(propData.name)"
        @click="getNumber(propData.name, $event)"
        v-html="`${propData.name}<span class='title-tips' tips></span>`"
      >
      </span>
    </div>
    <div class="law-content__text" v-if="validContent(propData.values)">
      <div v-for="(str, i) in propData.values" :key="i">
        <textItem
          :str="str"
          @select="handleSelectItem"
          @copy="handleCopy"
          @addClick="addClick"
          :index="i"
          :title="propData.name"
          :lawTitle="lawTitle"
          :textArr="textArr"
          :checkedAll="checkedAll"
          :checkedParent="checked"
          :isAddition="
            propData.values.length - 1 === i &&
              propData.addition_data &&
              propData.additionShow !== undefined
          "
          :additionShow.sync="propData.additionShow"
        />
      </div>
    </div>
    <template v-if="validProp(propData.values)">
      <div v-for="(law, i) in propData.values" :key="i">
        <law-item
          :currentObj="currentObj"
          :propData="law"
          :lawTitle="lawTitle"
          @copy="handleCopy"
          @showLinkPage="showLinkPage"
          :showCheck="true"
          @collect="collect"
        />
      </div>
    </template>
    <van-popup v-model="show" position="bottom" :style="{ height: '30%' }">
      <div class="show-text">
        <p>{{ showTextP }}</p>
        <span>
          {{ showText }}
        </span>
        <div
          v-for="(item, index) in showTextB"
          :key="index"
          class="show-text-bdiv"
          v-show="showModel === 1"
        >
          <span class="show-text-bdiv-name">[{{ item.name }}]</span>
          <span>{{ item.value }}</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import textItem from './text-item-concise.vue'
import { mapState } from 'vuex'
export default {
  name: 'law-item',
  props: {
    propData: Object,
    lawTitle: String,
    currentObj: Object
  },
  components: { textItem },
  data() {
    return {
      checked: false,
      showCopy: false,
      collectionTextArr: [],
      checkedAll: false,
      textArr: [],
      copyTextArr: [],
      collectionText: '',
      show: false,
      showText: '',
      showTextP: '',
      showTextB: [],
      showModel: 0
    }
  },
  computed: {
    ...mapState('searchReport', ['clearCheck']),
    isTiao() {
      return this.propData.type === '条'
    }
  },
  methods: {
    fmtName(name) {
      const index = name.indexOf('【')
      let Nnewname = ''
      if (index === -1) {
        Nnewname = name
      } else {
        Nnewname = name.substr(0, index)
        Nnewname = Nnewname.trim()
      }
      return Nnewname
    },
    addClick(e) {
      // console.log(e, '加一层组件 value.target 替换成 value')
      this.showTextP = ''
      this.showTextB = []
      const value = e.target
      if (value.localName === 'em') {
        console.log('点击了红色', value.dataset.lawid)
        if (value.innerText.indexOf('《') === -1) {
          const params = {
            law_id: value.dataset.lawid,
            name: value.innerText
          }
          // 发送请求
          this.showModel = 0
          this.vanpFn(params)
          this.showTextP = params.name
        } else {
          this.vanpFnB(value.dataset.lawid)
          this.showModel = 1
          // this.showTextP = params.name
        }
      }
    },
    handleCopy(text) {
      console.log('emit--------------->>', text)
      this.$emit('copy', text)
    },
    vanpFn(params) {
      this.$axios
        .post(`${this.$pydataBase}/api/lawid/content`, params)
        .then(res => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              this.showText = res.data.result ? res.data.result : '暂无数据'
              this.show = true
            }
          }
        })
    },
    vanpFnB(params) {
      this.$axios
        .get(`${this.$pydataBase}/api/nvi/law/detail?law_id=${params}`)
        .then(res => {
          if (res.status === 200) {
            if (res.data.code === 200) {
              // data.more_info:[{}]
              this.showTextB = res.data.result.more_info
              this.show = true
            }
          }
        })
    },
    copy(e) {
      const parentEl = e.target.parentElement.parentElement
      const el = parentEl
        .getElementsByClassName('tiao')[0]
        .getElementsByClassName('title-tips')[0]
      console.log('copy ====>', this.lawTitle)
      if (!this.lawTitle) {
        this.lawTitle = ''
      }
      let str = this.lawTitle + '\n' + this.propData.name + '\n'
      if (this.propData.values) {
        this.propData.values.forEach((item, index) => {
          str += item + '\n'
        })
      }
      const transfer = document.createElement('textarea')
      document.body.appendChild(transfer)
      transfer.value = str // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      this.$notify({
        type: 'success',
        message: '已复制，请粘贴到QQ或微信中'
      })
      setTimeout(() => {
        this.showCopy = false
        if (el) {
          el.style.opacity = 0
        }
      }, 1000)
    },
    // 选中父级
    selectTittle() {
      this.checked = !this.checked
      if (this.propData.values) {
        this.propData.values.forEach((item, index) => {
          if (this.checked) {
            if (typeof item === 'string') {
              this.collectionTextArr.push({ text: item, index })
            }
          } else {
            this.collectionTextArr = []
          }
        })
      }
      this.checkedAll = this.checked
      if (
        this.checked &&
        this.collectionTextArr &&
        this.collectionTextArr.length
      ) {
        this.$emit('collect', {
          name: this.propData.name,
          arr: this.collectionTextArr,
          isCancle: false
        })
      }
      if (!this.checked) {
        this.$emit('collect', {
          name: this.propData.name,
          arr: this.collectionTextArr,
          isCancle: true
        })
      }
      // console.log(this.collectionTextArr, '======> 选中父级 collectionTextArr')
    },
    // 选中子集
    handleSelectItem(payload) {
      const { flag, index, text } = payload
      if (flag) {
        this.collectionTextArr.push({ text, index })
        this.checked = true
      } else {
        const currentIndex = this.collectionTextArr.findIndex(
          item => item.index === index
        )
        this.collectionTextArr.splice(currentIndex, 1)
      }
      if (this.collectionTextArr.length === 0) {
        this.checked = false
        this.$emit('collect', {
          isCancle: true,
          name: this.propData.name,
          arr: this.collectionTextArr
        })
      } else {
        this.$emit('collect', {
          isCancle: false,
          name: this.propData.name,
          arr: this.collectionTextArr
        })
      }
      // console.log(this.propData.values.length === this.collectionTextArr.length, ' ===== 是否全选')
      // console.log(this.collectionTextArr, '======> 选中子集 collectionTextArr')
    },
    collect(payload) {
      if (
        this.propData.type === '正文' ||
        this.propData.type === '编' ||
        this.propData.type === '章' ||
        this.propData.type === '条' ||
        this.propData.type === '节'
      ) {
        // console.log('payload**********向上级传递')
        this.$emit('collect', payload)
      }
    },
    // 调用父组件lawcontent中的方法展开包含 判例、法律、知识、3个tab的页面
    clickHandle(activeindex, panliname, falvname, zhishiname, lawname) {
      //  console.log('21312321',activeindex,panliname, falvname, zhishiname, lawname)
      console.log('发送emit')
      this.$bus.$emit(
        'changeActiveFn',
        activeindex,
        panliname,
        falvname,
        zhishiname,
        lawname
      )
    },
    validProp(arr) {
      // console.log('validprop', arr.length)
      if (arr && arr.length && typeof arr[0] !== 'string') {
        return true
      }
      return false
    },
    validContent(arr) {
      return arr && arr.length && typeof arr[0] === 'string'
    },
    showLinkPage(clip, e) {
      this.$emit('showLinkPage', clip)
    },
    // 点击标题
    getNumber(name, e) {
      // console.log('getNumber', e)
      Array.from(document.querySelectorAll('span[tips]')).forEach(
        (item, index) => {
          if (item === e.target.children[0]) {
            return
          }
          item.style.opacity = 0
        }
      )
      this.$axios
        .get(
          `${this.$pydataBase}/api/law/relatedPrecedent?law=《${
            this.title
          }》${name}`
        )
        .then(res => {
          if (e.target.children && e.target.children[0]) {
            e.target.children[0].innerText = res.data.result
            e.target.children[0].style.opacity =
              e.target.children[0].style.opacity === '0.5' ? '0' : '0.5'
          } else {
            e.target.innerText = ''
          }
        })
      if (e.target.style.opacity === 0) {
        this.showCopy = false
      } else {
        this.showCopy = !this.showCopy
      }
    }
  },
  created() {
    // console.log(this.currentObj)
    if (this.currentObj && this.propData.type === '条') {
      this.currentObj.arr.forEach(item => {
        if (item.name === this.propData.name) {
          this.checked = true
          this.textArr = item.arr
          this.collectionTextArr = this.textArr
        }
      })
    }
  },
  mounted() {},
  watch: {
    clearCheck(val) {
      if (val) {
        this.checked = false
        this.collectionTextArr = []
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.law-item-comp
  & .law-item-comp__title
    font-size 20px
    line-height 24px
    color rgba(0, 0, 0, 1)
    margin-top 40px
    position relative
    & .check
      position absolute
      width 30px
      display flex
      left 0
      top 0
      justify-content center
      height 24px
      align-items center
      /deep/.van-checkbox__icon
        .van-icon
          font-size 8px
          line-height 30px
    & .icon-fuzhi
      font-size 12px !important
      color #ccc
      opacity 0.5
    & .name
      font-size 18px
      font-weight bold
      margin-left 30px
      line-height 24px
      &.tiao
        text-align left
    & .title-link
      color #999
      line-height 24px
      margin-left 4px
      & .icon-fuzhi
        font-size 14px !important
        color #ccc
        opacity 0.5
        cursor pointer
      & .iconkong
        font-size 14px !important
    & .stat_num
      float right
      right 0
      font-size 14px
      font-weight normal
      color #999
      line-height 24px
  & .law-content__text
    margin-top 5px
    font-size 14px
.dropdown
  position relative
  display inline-block
.dropdown-content
  white-space nowrap
  font-size 13px
  display none
  position absolute
  background-color rgb(243, 246, 245)
  min-width 120px
  box-shadow 0px 8px 16px 0px rgba(0, 0, 0, 0.2)
  padding 12px 16px
  z-index 1
  // left: -50px
  right 0px
  color rgb(91, 220, 217)
.dropdown:hover .dropdown-content
  display block
</style>
<style lang="stylus">
.van-notify--success
  z-index 999999999 !important
</style>
