var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "law-item-comp" },
    [
      _c("div", { staticClass: "law-item-comp__title" }, [
        _vm.isTiao
          ? _c(
              "div",
              {
                staticClass: "check",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.selectTittle.apply(null, arguments)
                  },
                },
              },
              [
                _c("van-checkbox", {
                  attrs: { shape: "square" },
                  model: {
                    value: _vm.checked,
                    callback: function ($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("span", {
          class: { name: true, tiao: _vm.propData.type === "条" },
          attrs: { id: _vm.fmtName(_vm.propData.name) },
          domProps: {
            innerHTML: _vm._s(
              `${_vm.propData.name}<span class='title-tips' tips></span>`
            ),
          },
          on: {
            click: function ($event) {
              return _vm.getNumber(_vm.propData.name, $event)
            },
          },
        }),
        _vm.propData.type === "条"
          ? _c("span", { staticClass: "title-link" }, [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.propData.clipDataShow > 0,
                    expression: "propData.clipDataShow > 0",
                  },
                ],
                staticClass: "iconfont icon-link1",
                on: {
                  click: function ($event) {
                    return _vm.showLinkPage(_vm.propData.clip_data, $event)
                  },
                },
              }),
              _c("span", { staticClass: "iconkong" }, [_vm._v("  ")]),
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showCopy,
                    expression: "showCopy",
                  },
                ],
                staticClass: "iconfont icon-fuzhi",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.copy.apply(null, arguments)
                  },
                },
              }),
            ])
          : _vm._e(),
        _vm.propData.type === "条"
          ? _c("span", { staticClass: "stat_num dropdown" }, [
              _c("span", [_vm._v(_vm._s(_vm.propData.quote_num || 0) + " /")]),
              _c("span", [_vm._v(_vm._s(_vm.propData.cite_ledge_num || 0))]),
              _c("div", { staticClass: "dropdown-content" }, [
                _c(
                  "p",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.clickHandle(
                          0,
                          _vm.propData.quote_num || 0,
                          _vm.propData.cite_law_num || 0,
                          _vm.propData.cite_ledge_num || 0,
                          _vm.propData.name
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      " 被" +
                        _vm._s(_vm.propData.quote_num || 0) +
                        "篇判例引用 "
                    ),
                  ]
                ),
                _c(
                  "p",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.clickHandle(
                          1,
                          _vm.propData.quote_num || 0,
                          _vm.propData.cite_law_num || 0,
                          _vm.propData.cite_ledge_num || 0,
                          _vm.propData.name
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      " 被" +
                        _vm._s(_vm.propData.cite_ledge_num || 0) +
                        "篇知识引用 "
                    ),
                  ]
                ),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm.validContent(_vm.propData.values)
        ? _c(
            "div",
            { staticClass: "law-content__text" },
            _vm._l(_vm.propData.values, function (str, i) {
              return _c(
                "div",
                { key: i },
                [
                  _c("textItem", {
                    attrs: {
                      str: str,
                      index: i,
                      title: _vm.propData.name,
                      lawTitle: _vm.lawTitle,
                      textArr: _vm.textArr,
                      checkedAll: _vm.checkedAll,
                      checkedParent: _vm.checked,
                      isAddition:
                        _vm.propData.values.length - 1 === i &&
                        _vm.propData.addition_data &&
                        _vm.propData.additionShow !== undefined,
                      additionShow: _vm.propData.additionShow,
                    },
                    on: {
                      select: _vm.handleSelectItem,
                      copy: _vm.handleCopy,
                      addClick: _vm.addClick,
                      "update:additionShow": function ($event) {
                        return _vm.$set(_vm.propData, "additionShow", $event)
                      },
                      "update:addition-show": function ($event) {
                        return _vm.$set(_vm.propData, "additionShow", $event)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm.propData.additionShow !== undefined
        ? _c("addition", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.propData.additionShow,
                expression: "propData.additionShow",
              },
            ],
            attrs: { addition_data: _vm.propData.addition_data },
          })
        : _vm._e(),
      _vm.validProp(_vm.propData.values)
        ? _vm._l(_vm.propData.values, function (law, i) {
            return _c(
              "div",
              { key: i },
              [
                _c("law-item", {
                  attrs: {
                    currentObj: _vm.currentObj,
                    propData: law,
                    lawTitle: _vm.lawTitle,
                    showCheck: true,
                  },
                  on: {
                    copy: _vm.handleCopy,
                    showLinkPage: _vm.showLinkPage,
                    collect: _vm.collect,
                  },
                }),
              ],
              1
            )
          })
        : _vm._e(),
      _c(
        "van-popup",
        {
          style: { height: "30%" },
          attrs: { position: "bottom" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            { staticClass: "show-text" },
            [
              _c("p", [_vm._v(_vm._s(_vm.showTextP))]),
              _c("span", [_vm._v(" " + _vm._s(_vm.showText) + " ")]),
              _vm._l(_vm.showTextB, function (item, index) {
                return _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showModel === 1,
                        expression: "showModel === 1",
                      },
                    ],
                    key: index,
                    staticClass: "show-text-bdiv",
                  },
                  [
                    _c("span", { staticClass: "show-text-bdiv-name" }, [
                      _vm._v("[" + _vm._s(item.name) + "]"),
                    ]),
                    _c("span", [_vm._v(_vm._s(item.value))]),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }