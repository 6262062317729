var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "min-width": "264px" } },
    _vm._l(_vm.lawContent.catlog_data, function (item, index) {
      return _c(
        "ul",
        { key: index },
        [_c("Tree", { attrs: { data: item } })],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }