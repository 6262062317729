<template>
  <div class="lawCorrelation">
    <van-collapse v-model="activeName"
                  accordion>
      <van-collapse-item title="基本信息"
                         name="1">
        <div v-for="(more_info,index) in lawContent.more_info"
             :key="index + 'more_info'"
             class="basic">
          <p class="Namebasic">{{more_info.name}}</p>
          <p class="basicc">:</p>
          <p class="basicValue">{{more_info.value}}</p>
        </div>
      </van-collapse-item>
      <van-collapse-item title="背景资料"
                         name="2">
        <!-- 网店吸粉获客、会员分层营销、一机多种收款，告别经营低效和客户流失 -->
      </van-collapse-item>
      <van-collapse-item title="引用法规"
                         name="3">
        <div v-for="(meditate_data,index) in lawContent.meditate_data"
             :key="index + 'meditate_data'"
             class="regulations">
          <span>{{meditate_data.title}}</span>
        </div>
      </van-collapse-item>
      <van-collapse-item title="引用本篇的法规、判例、文章"
                         name="4">
        <!-- 线上拓客，随时预约，贴心顺手的开单收银 -->
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>
export default {
  name: 'lawCorrelation',
  props: ['lawContent'],
  data () {
    return {
      activeName: 1
    }
  }
}
</script>
<style lang="stylus" scoped>
.lawCorrelation
  width 264px
  height 100%
  padding 15px !important
.basic
  display flex
  font-size 15px
  font-family PingFangSC
  font-weight 400
  color rgba(0, 0, 0, 1)
.Namebasic
  width 70px !important
.basicValue
  margin-left 20px
.regulations
  margin-top 20px
  font-size 15px
</style>
