var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.addition_data && _vm.addition_data.length > 0
    ? _c("div", { staticClass: "addition" }, [
        _c(
          "div",
          { staticClass: "addition-list" },
          [
            _vm._l(_vm.addition_data, function (item, index) {
              return [
                item.values.length > 0
                  ? _c("div", { key: index, staticClass: "addition-box" }, [
                      _c(
                        "div",
                        { staticClass: "addition-title" },
                        [
                          _c("span", { staticClass: "addition-title-span" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                          _c("van-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: item.show,
                                expression: "item.show",
                              },
                            ],
                            staticStyle: { transform: "rotate(90deg)" },
                            attrs: { name: "play" },
                            on: {
                              click: function ($event) {
                                item.show = false
                              },
                            },
                          }),
                          _c("van-icon", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !item.show,
                                expression: "!item.show",
                              },
                            ],
                            attrs: { name: "play" },
                            on: {
                              click: function ($event) {
                                item.show = true
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.show,
                              expression: "item.show",
                            },
                          ],
                          staticClass: "addition-content",
                        },
                        _vm._l(item.values, function (childItem, i) {
                          return _c(
                            "div",
                            { key: i, staticClass: "addition-content-box" },
                            [
                              item.title === "经典案例" ||
                              item.title === "典型案例" ||
                              item.title === "公报案例" ||
                              item.title === "指导案例" ||
                              item.title === "参阅案例"
                                ? _c(
                                    "div",
                                    { staticClass: "addition-content-title" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              childItem.case_name_copy =
                                                !childItem.case_name_copy
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(childItem.case_name))]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: childItem.case_name_copy,
                                              expression:
                                                "childItem.case_name_copy",
                                            },
                                          ],
                                          staticClass:
                                            "addition-content-text-btn",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "iconfont icon-fuzhi",
                                            on: {
                                              click: function ($event) {
                                                return _vm.copy(
                                                  childItem.case_name
                                                )
                                              },
                                            },
                                          }),
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-fasong1",
                                            on: {
                                              click: function ($event) {
                                                return _vm.toDetail(childItem)
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    { staticClass: "addition-content-title" },
                                    [
                                      childItem.timeliness &&
                                      childItem.timeliness !== "现行有效"
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                color: "red",
                                                "margin-right": "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(childItem.timeliness)
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              childItem.law_title_copy =
                                                !childItem.law_title_copy
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(childItem.law_title) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: childItem.law_title_copy,
                                              expression:
                                                "childItem.law_title_copy",
                                            },
                                          ],
                                          staticClass:
                                            "addition-content-text-btn",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "iconfont icon-fuzhi",
                                            on: {
                                              click: function ($event) {
                                                return _vm.copy(
                                                  childItem.law_title
                                                )
                                              },
                                            },
                                          }),
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-fasong1",
                                            on: {
                                              click: function ($event) {
                                                return _vm.toDetail(childItem)
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                              _vm._l(
                                childItem.child,
                                function (childItem2, i2) {
                                  return _c("div", { key: i2 }, [
                                    item.title === "关联法条" ||
                                    item.title === "司法解释"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "addition-content-text",
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      childItem2.title_copy =
                                                        !childItem2.title_copy
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(childItem2.title) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        childItem2.title_copy,
                                                      expression:
                                                        "childItem2.title_copy",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "addition-content-text-btn",
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "iconfont icon-fuzhi",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.copy(
                                                          childItem2.title
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _c("i", {
                                                    staticClass:
                                                      "iconfont icon-fasong1",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.toDetail(
                                                          childItem,
                                                          childItem2.title
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "addition-content-text-div",
                                                class: `${
                                                  childItem2.textMore
                                                    ? "addition-content-text-div-hidden"
                                                    : "addition-content-text-div-show"
                                                }`,
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        childItem2.text_copy =
                                                          !childItem2.text_copy
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          childItem2.text
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                childItem2.textMore !==
                                                undefined
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                          color: "#409EFF",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.more(
                                                              true,
                                                              index,
                                                              i,
                                                              i2
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("收起")]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          childItem2.textMore,
                                                        expression:
                                                          "childItem2.textMore",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "addition-content-text-div-more",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "iconfont icon-more",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.more(
                                                            false,
                                                            index,
                                                            i,
                                                            i2
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value:
                                                          childItem2.text_copy,
                                                        expression:
                                                          "childItem2.text_copy",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "addition-content-text-btn",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "iconfont icon-fuzhi",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.copy(
                                                            childItem2.text
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c("i", {
                                                      staticClass:
                                                        "iconfont icon-fasong1",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.toDetail(
                                                            childItem,
                                                            childItem2.title
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                }
                              ),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }