var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "text-item" }, [
    _c(
      "div",
      {
        staticClass: "check",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.selectText.apply(null, arguments)
          },
        },
      },
      [
        _c("van-checkbox", {
          attrs: { shape: "square" },
          model: {
            value: _vm.checked,
            callback: function ($$v) {
              _vm.checked = $$v
            },
            expression: "checked",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "text" }, [
      _c("span", {
        domProps: { innerHTML: _vm._s(_vm.str) },
        on: { click: _vm.clickText, copy: _vm.handleCopy },
      }),
      _vm.isAddition
        ? _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.additionShow,
                expression: "additionShow",
              },
            ],
            staticClass: "iconfont icon-dakai-01",
            on: {
              click: function ($event) {
                return _vm.setAdditionShow(false)
              },
            },
          })
        : _vm._e(),
      _vm.isAddition
        ? _c("i", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.additionShow,
                expression: "!additionShow",
              },
            ],
            staticClass: "iconfont icon-dakai-011",
            on: {
              click: function ($event) {
                return _vm.setAdditionShow(true)
              },
            },
          })
        : _vm._e(),
      _c("span", [
        _vm._v("  "),
        _c("i", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showCopy,
              expression: "showCopy",
            },
          ],
          staticClass: "iconfont icon-fuzhi",
          on: { click: _vm.copy },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }