var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.lawContent.content, function (law, index) {
        return _c("div", { key: index + "content" }, [
          law.name
            ? _c(
                "div",
                [
                  _c("law-item", {
                    attrs: {
                      propData: law,
                      lawTitle: _vm.lawTitle,
                      currentObj: _vm.currentObj,
                    },
                    on: {
                      collect: _vm.emitCollect,
                      changeActiveFn: _vm.changeActive,
                      showLinkPage: _vm.showOtherPage,
                      copy: _vm.handleCopy,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
      _c(
        "van-popup",
        {
          style: { height: "100%", width: "70%" },
          attrs: { position: "left" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "div",
            { staticClass: "clip-data" },
            _vm._l(_vm.clip_data, function (item, index) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.values && item.values.length > 0,
                      expression: "item.values && item.values.length > 0",
                    },
                  ],
                  key: index,
                  staticClass: "clip-data-box",
                },
                [
                  _c("div", { staticClass: "clip-data-box-title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "clip-data-box-content" },
                    _vm._l(item.values, function (childItem, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          on: {
                            click: function ($event) {
                              return _vm.tolawDetail(childItem)
                            },
                          },
                        },
                        [
                          typeof childItem === "string"
                            ? _c("div", {
                                staticClass: "clip-data-box-content-text",
                                domProps: { innerHTML: _vm._s(childItem) },
                              })
                            : _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "clip-data-box-content-title",
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(childItem.law_title) + " "
                                    ),
                                  ]
                                ),
                                typeof childItem.text === "string"
                                  ? _c("div", {
                                      staticClass: "clip-data-box-content-text",
                                      domProps: {
                                        innerHTML: _vm._s(childItem.text),
                                      },
                                    })
                                  : _vm._e(),
                              ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm.knowledge
        ? _c(
            "div",
            { staticClass: "law-citation" },
            [
              _c(
                "fb-header",
                { attrs: { slot: "header" }, slot: "header" },
                [
                  _c(
                    "fb-button",
                    {
                      attrs: {
                        slot: "left",
                        icon: "back",
                        size: "small",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.knowledge = false
                        },
                      },
                      slot: "left",
                    },
                    [_vm._v(" 返回 ")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "van-tabs",
                    {
                      attrs: { swipeable: "" },
                      model: {
                        value: _vm.active,
                        callback: function ($$v) {
                          _vm.active = $$v
                        },
                        expression: "active",
                      },
                    },
                    [
                      _c(
                        "van-tab",
                        { attrs: { title: "引用判例" + _vm.panliname + "篇" } },
                        [
                          _vm.loading
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "30px",
                                    height: "30px",
                                    margin: "20px auto 20px",
                                  },
                                },
                                [
                                  _c("van-loading", {
                                    attrs: { color: "#1989fa" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.active == "0"
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-content": "space-between",
                                    padding: "10px 5px",
                                    "box-sizing": "border-box",
                                    "background-color":
                                      "rgba(242, 242, 242, 1)",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "law-detail-top",
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        flex: "1",
                                      },
                                    },
                                    [
                                      _vm._l(_vm.caseTree, function (item, i) {
                                        return _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.panliChecked,
                                                expression: "!panliChecked",
                                              },
                                            ],
                                            key: i,
                                            staticStyle: {
                                              flex: "1",
                                              "text-align": "center",
                                            },
                                            on: { click: _vm.ShowFilter },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.title)),
                                            ]),
                                            _c("van-icon", {
                                              attrs: { name: "arrow-down" },
                                            }),
                                          ],
                                          1
                                        )
                                      }),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.panliChecked,
                                              expression: "panliChecked",
                                            },
                                          ],
                                          staticStyle: {
                                            color: "#409eff",
                                            display: "flex",
                                          },
                                        },
                                        [
                                          _c("van-checkbox", {
                                            staticStyle: {
                                              "margin-left": "14px",
                                            },
                                            attrs: { shape: "square" },
                                            on: { change: _vm.changeCheckAll },
                                            model: {
                                              value: _vm.panliCheckedAll,
                                              callback: function ($$v) {
                                                _vm.panliCheckedAll = $$v
                                              },
                                              expression: "panliCheckedAll",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "20px",
                                              },
                                              on: { click: _vm.cancel },
                                            },
                                            [
                                              _vm._v(
                                                "取消(" +
                                                  _vm._s(_vm.panliCheckedNum) +
                                                  ")"
                                              ),
                                            ]
                                          ),
                                          _vm.userInfo
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "20px",
                                                  },
                                                  on: { click: _vm.share },
                                                },
                                                [_vm._v("分享")]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "20px",
                                              },
                                              on: { click: _vm.copy },
                                            },
                                            [_vm._v("复制")]
                                          ),
                                          _vm.userInfo
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "20px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.joinCollectBox()
                                                    },
                                                  },
                                                },
                                                [_vm._v("加入收集箱")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "panli" },
                            [
                              _vm._l(_vm.caselaelist, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: { display: "flex" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { "margin-top": "22px" } },
                                      [
                                        _c("van-checkbox", {
                                          attrs: { shape: "square" },
                                          on: { change: _vm.changeCheck },
                                          model: {
                                            value: item.checked,
                                            callback: function ($$v) {
                                              _vm.$set(item, "checked", $$v)
                                            },
                                            expression: "item.checked",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "contet",
                                        staticStyle: { flex: "1" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.GoDetail(
                                              item.doc_id,
                                              "",
                                              "",
                                              ""
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "font-size": "15px",
                                              color: "#000",
                                              "font-weight": "bold",
                                              display: "flex",
                                            },
                                          },
                                          [
                                            item.case_attr !== "普通案例" &&
                                            item.case_attr
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      color: "white",
                                                      "text-align": "center",
                                                      "line-height": "35px",
                                                      "min-width": "90px",
                                                      background: "#ff9c39",
                                                      height: "35px",
                                                      "z-index": "999",
                                                      "border-radius": "3px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.case_attr) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "min-height": "35px",
                                                  "margin-left": "5px",
                                                  color: "#333",
                                                  "font-weight": "bold",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.case_name) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          item.court_name
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(item.court_name) + "/"
                                                ),
                                              ])
                                            : _vm._e(),
                                          item.case_id
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(item.case_id) + "/"
                                                ),
                                              ])
                                            : _vm._e(),
                                          _c("span", [
                                            _vm._v(_vm._s(item.pub_date)),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              _vm.panliname >= 10
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#333",
                                        margin: "10px auto 10px",
                                        "text-align": "center",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.next(0)
                                        },
                                      },
                                    },
                                    [_vm._v(" 下一页 ")]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                      _c(
                        "van-tab",
                        {
                          attrs: { title: "引用知识" + _vm.zhishiname + "篇" },
                        },
                        [
                          _vm.loading
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "30px",
                                    height: "30px",
                                    margin: "20px auto 20px",
                                  },
                                },
                                [
                                  _c("van-loading", {
                                    attrs: { color: "#1989fa" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "panli" },
                            [
                              _vm._l(_vm.caselaelist, function (item, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "contet" },
                                  [
                                    _c("div", [
                                      _vm._v(" " + _vm._s(item.title) + " "),
                                    ]),
                                    _c("div", [
                                      item.origin
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.origin) + "/"),
                                          ])
                                        : _vm._e(),
                                      item.pub_date
                                        ? _c("span", [
                                            _vm._v(_vm._s(item.pub_date) + "/"),
                                          ])
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(_vm._s(item.publisher)),
                                      ]),
                                    ]),
                                  ]
                                )
                              }),
                              _vm.zhishiname >= 10
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#333",
                                        margin: "10px auto 10px",
                                        "text-align": "center",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.next(2)
                                        },
                                      },
                                    },
                                    [_vm._v(" 下一页 ")]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.appealDetail
        ? _c(
            "div",
            { staticClass: "detail_area" },
            [
              _c("percent-detail", {
                attrs: {
                  id: _vm.detailshow.doc_id,
                  words: _vm.detailshow.words,
                  forword: _vm.detailshow.forword,
                  titleName: _vm.detailshow.name,
                },
                on: {
                  changeflag: function ($event) {
                    _vm.appealDetail = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.lawflag
        ? _c(
            "div",
            { staticClass: "detail_area" },
            [
              _c("law-detail", {
                attrs: {
                  id: _vm.lawOptions.law_id,
                  title: _vm.lawOptions.title,
                  words: _vm.lawOptions.words,
                  catlog: _vm.lawOptions.catlog,
                  lawContent: _vm.lawOptions.data,
                },
                on: {
                  changeflag: function ($event) {
                    _vm.lawflag = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "keep-alive",
        [
          Object.keys(_vm.TreeSelect.data).length > 0
            ? _c("treeSelect", {
                attrs: { keywords: _vm.save_keyword },
                on: { search: _vm.pushFilterCondition },
                model: {
                  value: _vm.TreeSelect,
                  callback: function ($$v) {
                    _vm.TreeSelect = $$v
                  },
                  expression: "TreeSelect",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }