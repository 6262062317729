<template>
  <div class="guidance">
    <div class="img-box">
      右上角点击完整，换到完整模式，查看关联法条等数据
    </div>
    <div class="btn-box">
      <van-button type="info" size="normal" @click="sure">知道了</van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['type', 'animationId'],
  components: {},
  computed: {},
  name: 'guidance',
  data() {
    return {
      src: ''
    }
  },

  methods: {
    sure() {
      this.$axios.post(`${this.$base}/document/animation/save`, {
        type: this.type,
        animationId: this.animationId
      })
      this.$emit('close')
    }
  },
  mounted() {},
  created() {
    if (this.animationId === '2001') {
      this.src = ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.guidance
  position relative
  width 100%
  height 100%
  padding-top 180px
  & .img-box
    position absolute
    flex 1
    margin-bottom 10px
    background #FFF
    padding 5px 10px
    width 150px
    top 54px
    right 38px
    color #f90
  & .img-box:after
    position:absolute;
    top:-40px;
    right:0px;
    border-top:20px solid transparent;
    content:" "; // content 不要漏了，漏了会显示不出来
    width:0px;
    height:0px;
    border-right:20px solid transparent;
    border-bottom:20px solid #FFF;
    border-left:20px solid transparent;
  & .btn-box
    display flex
    align-items center
    justify-content center
</style>
