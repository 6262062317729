<template>
  <div class="addition" v-if="addition_data && addition_data.length > 0">
    <div class="addition-list">
      <template v-for="(item, index) in addition_data">
        <div class="addition-box" v-if="item.values.length > 0" :key="index">
          <div class="addition-title">
            <span class="addition-title-span">{{ item.title }}</span>
            <van-icon
              name="play"
              v-show="item.show"
              @click="item.show = false"
              style="transform:rotate(90deg)"
            />
            <van-icon
              name="play"
              v-show="!item.show"
              @click="item.show = true"
            />
          </div>
          <div class="addition-content" v-show="item.show">
            <div
              class="addition-content-box"
              v-for="(childItem, i) in item.values"
              :key="i"
            >
              <div
                class="addition-content-title"
                v-if="
                  item.title === '经典案例' ||
                    item.title === '典型案例' ||
                    item.title === '公报案例' ||
                    item.title === '指导案例' ||
                    item.title === '参阅案例'
                "
              >
                <span
                  @click="childItem.case_name_copy = !childItem.case_name_copy"
                  >{{ childItem.case_name }}</span
                >
                <span
                  class="addition-content-text-btn"
                  v-show="childItem.case_name_copy"
                >
                  <i
                    class="iconfont icon-fuzhi"
                    @click="copy(childItem.case_name)"
                  ></i>
                  <i
                    class="iconfont icon-fasong1"
                    @click="toDetail(childItem)"
                  ></i>
                </span>
              </div>
              <div class="addition-content-title" v-else>
                <span
                  style="color: red;margin-right: 10px"
                  v-if="
                    childItem.timeliness && childItem.timeliness !== '现行有效'
                  "
                  >{{ childItem.timeliness }}</span
                >
                <span
                  @click="childItem.law_title_copy = !childItem.law_title_copy"
                >
                  {{ childItem.law_title }}
                </span>
                <span
                  class="addition-content-text-btn"
                  v-show="childItem.law_title_copy"
                >
                  <i
                    class="iconfont icon-fuzhi"
                    @click="copy(childItem.law_title)"
                  ></i>
                  <i
                    class="iconfont icon-fasong1"
                    @click="toDetail(childItem)"
                  ></i>
                </span>
              </div>
              <div v-for="(childItem2, i2) in childItem.child" :key="i2">
                <div
                  class="addition-content-text"
                  v-if="item.title === '关联法条' || item.title === '司法解释'"
                >
                  <div>
                    <span
                      @click="childItem2.title_copy = !childItem2.title_copy"
                    >
                      {{ childItem2.title }}
                    </span>
                    <span
                      v-show="childItem2.title_copy"
                      class="addition-content-text-btn"
                    >
                      <i
                        class="iconfont icon-fuzhi"
                        @click="copy(childItem2.title)"
                      ></i>
                      <i
                        class="iconfont icon-fasong1"
                        @click="toDetail(childItem, childItem2.title)"
                      ></i>
                    </span>
                  </div>
                  <div
                    class="addition-content-text-div"
                    :class="
                      `${
                        childItem2.textMore
                          ? 'addition-content-text-div-hidden'
                          : 'addition-content-text-div-show'
                      }`
                    "
                  >
                    <span @click="childItem2.text_copy = !childItem2.text_copy">
                      {{ childItem2.text }}
                    </span>
                    <span
                      v-if="childItem2.textMore !== undefined"
                      style="cursor: pointer; color: #409EFF;"
                      @click="more(true, index, i, i2)"
                      >收起</span
                    >
                    <div
                      class="addition-content-text-div-more"
                      v-show="childItem2.textMore"
                    >
                      <i
                        class="iconfont icon-more"
                        @click="more(false, index, i, i2)"
                      ></i>
                    </div>
                    <span
                      class="addition-content-text-btn"
                      v-show="childItem2.text_copy"
                    >
                      <i
                        class="iconfont icon-fuzhi"
                        @click.stop="copy(childItem2.text)"
                      ></i>
                      <i
                        class="iconfont icon-fasong1"
                        @click.stop="toDetail(childItem, childItem2.title)"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['addition_data'],
  components: {},
  computed: {},
  name: 'addition',
  data() {
    return {}
  },

  methods: {
    copy(text) {
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
      this.$notify({
        type: 'success',
        message: '内容已复制'
      })
    },
    // 详情
    toDetail(item, title = '') {
      if (item.doc) {
        // 跳转打开判例详情
        // const url = `${document.location.origin}/search?docid=${item.doc}`
        // window.open(url, '_self')
        this.$bus.$emit('openDetail', { id: item.doc, type: 'doc' })
      } else if (item.law_id) {
        if (title) {
          // 跳转打开法律详情,滚动到对应的条目
          // const url = `${document.location.origin}/search?lawid=${
          //   item.law_id
          // }&keywords=&catlog=&aName=${title}`
          // window.open(url, '_self')
          // this.$bus.$emit('toDetail', item.law_id)
          this.$bus.$emit('openDetail', {
            id: item.law_id,
            type: 'law',
            title: title
          })
        } else {
          // 跳转打开法律详情
          // const url = `${document.location.origin}/search?lawid=${
          //   item.law_id
          // }&keywords=&catlog=`
          // window.open(url, '_self')
          // this.$bus.$emit('toDetail', item.law_id)
          this.$bus.$emit('openDetail', { id: item.law_id, type: 'law' })
        }
      } else {
        // 既没有doc又没有law_id的，表示是当前法律
        if (item.title) {
          // 滚动到当前条目
          document.location.href =
            document.location.origin +
            '/search' +
            document.location.search +
            '#' +
            item.title
        }
      }
    },
    more(flag, index, i, i2) {
      this.addition_data[index].values[i].child[i2].textMore = flag
    }
  },
  mounted() {},
  created() {}
}
</script>

<style lang="stylus">
.addition
  position relative
  width 100%
  background-color: rgba(242, 242, 242, 1);
  & .addition-list
    padding 10px 10px
    box-sizing border-box
    & .addition-box
      & .addition-title
        & .addition-title-span
          color #FF9900
          font-size 12px
        & > i
          color #333
          cursor pointer
          font-size 12px
      & .addition-content
        & .addition-content-box
          position relative
          padding-left 10px
          box-sizing border-box
          & .addition-content-title
            color #000
            font-size 12px
            font-weight bold
            margin-bottom 5px
          & .addition-content-text
            font-size 14px
            color #666666
            margin-bottom 5px
            & span
              font-size 14px
          & .addition-content-text-div
            line-height 18px
            position relative
          & .addition-content-text-div-more
            position absolute
            bottom 0px
            right 0px
            background #f2f2f2
            width 22px
            & > i
              cursor pointer
              color #409EFF
          & .addition-content-text-div-hidden
            overflow hidden
            height 90px
          & .addition-content-text-div-show
            overflow visible
          & .addition-content-text-btn
            // position absolute
            display: inline-block
            width: 60px
            // margin-left 60px
            & i
              color rgb(86, 154, 249)
              cursor pointer
              margin-left 10px
              font-size 14px
</style>
