<template>
  <div class="lawMenu">
    <div class="title">
      <a
        :href="'#' + fmtName(data.name || data)"
        @click="setShowMenu(+new Date())"
      >
        <span
          class="left"
          v-html="
            data.name
              ? data.name +
                (data.sub_name ? '<br/>(' + data.sub_name + ')' : '')
              : data
          "
          >{{ data.name || data }}</span
        >
      </a>
      <span
        v-if="right"
        class="
            iconfont
            icon-child-off"
        @click="open()"
      ></span>
    </div>
    <div v-if="showopen">
      <tree-item
        v-for="(item, index) of data.value"
        :key="index"
        :data="item"
      ></tree-item>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  name: 'treeItem',
  props: ['data'],
  data() {
    return {
      right: false,
      showopen: false,
      e: 0 // 下拉框的收起和打开，0收起
    }
  },
  mounted() {
    if (this.data.type) {
      this.right = true
    }
  },
  methods: {
    ...mapMutations('search', ['setShowMenu']),
    open() {
      if (this.e === 0) {
        this.showopen = true
        this.e = 1
      } else {
        this.showopen = false
        this.e = 0
      }
      if (this.data.type) {
        this.right = true
      } else {
        this.right = false
      }
    },
    fmtName(name) {
      const index = name.indexOf('【')
      let Nnewname = ''
      if (index === -1) {
        Nnewname = name
      } else {
        Nnewname = name.substr(0, index)
      }
      return Nnewname
    }
  }
}
</script>
<style lang="stylus" scoped>
.lawMenu
  width 264px
  height 100%
  padding 8px !important
  .title
    font-size 14px
    font-family PingFangSC-Light
    font-weight bold
    color rgba(0, 0, 0, 1)
    // line-height 15px
    a
      color rgba(0, 0, 0, 1)
    .left
      display inline-block
      width 80%
      // border 1px solid red
    .icon-child-off
      float right
      margin-right 15px
</style>
