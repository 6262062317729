<template>
  <div>
    <div v-for="(law, index) in lawContent.content" :key="index + 'content'">
      <div v-if="law.name">
        <law-item
          @collect="emitCollect"
          :propData="law"
          :lawTitle="lawTitle"
          @changeActiveFn="changeActive"
          @showLinkPage="showOtherPage"
          :currentObj="currentObj"
          @copy="handleCopy"
        ></law-item>
      </div>
    </div>
  </div>
</template>
<script>
import { pydataBase } from '~api-config'
import LawItem from './law-item-concise'
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  // name: 'lawcontent',
  props: ['lawContent', 'catlog', 'title', 'lawid'],
  components: {
    LawItem
  },
  data() {
    return {
      caseTree: [],
      TreeSelect: {
        flag: false,
        data: {},
        title: ''
      },
      lawTitle: '',
      save_keyword: [],
      active: '0',
      loading: true,
      knowledge: false,
      caseraw: false,
      falv: false,
      caselaelist: [],
      show: false,
      offset: 0,
      data: [],
      name: '',
      panlipage: 1,
      panliname: '',
      zhishiname: '',
      falvname: '',
      listindex: -1,
      zhishi: false,
      total: '',
      panlinick: '',
      lawname: '',
      hatin: true,
      collection: [],
      currentObj: null,
      currentObjTextArr: [],
      // 是否正在加载数据
      isLoading: false,
      appealDetail: false,
      detailshow: {
        doc_id: '',
        words: '',
        forword: ''
      },
      lawflag: false,
      lawOptions: {},
      panliCheckedAll: false,
      panliChecked: false,
      panliCheckedNum: 0,
      panliCheckDocIds: [],
      reportId: null,
      searchCollectionId: null,
      lawFirmName: null,
      // 曲别针数据
      clip_data: []
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('searchReport', [
      'judgeReports',
      'lawReports',
      'criterionReports',
      'knowledgeReports',
      'contentReports',
      'anaReports',
      'textReport',
      'clearCheck'
    ]),
    ua() {
      return navigator.userAgent
    },
    panliCheckList() {
      return this.caselaelist.filter(item => item.checked === true)
    },
    tempTitle() {
      const today = new Date()
      const dayTime =
        today.getFullYear() +
        '.' +
        (today.getMonth() + 1) +
        '.' +
        today.getDate() +
        ' ' +
        (today.getHours() > 9 ? today.getHours() : '0' + today.getHours()) +
        ':' +
        (today.getMinutes() > 9 ? today.getMinutes() : '0' + today.getMinutes())
      const title = `${dayTime}${
        this.userInfo ? this.userInfo.nickName + '收集' : '某某收集'
      }${this.judgeReports.length > 0 ? this.judgeReports.length + '判' : ''}${
        this.lawReports.length > 0 ? this.lawReports.length + '法' : ''
      }${
        this.knowledgeReports.length > 0
          ? this.knowledgeReports.length + '知'
          : ''
      }${
        this.criterionReports.length > 0
          ? this.criterionReports.length + '标'
          : ''
      }${
        this.contentReports.length + this.anaReports.length > 0
          ? this.contentReports.length + this.anaReports.length + '文'
          : ''
      }`
      return title
    }
  },
  methods: {
    ...mapMutations('searchReport', ['SET_SEARCH_COLLECTION_ID']),
    ...mapActions('searchReport', ['setStampReport']),
    // 关联按钮弹窗中点击跳转
    tolawDetail(item) {
      if (item && item.law_id) {
        const str = item.text
          .trim()
          .substr(0, item.text.trim().indexOf('条') + 1)
        const reg = /^第.*(.*?)条/gi
        if (reg.test(str)) {
          this.lawDetail(item.law_id, '', str, '')
        } else {
          this.lawDetail(item.law_id, '', '', '')
        }
      }
    },
    // 初始化获取getCollectionID
    getCollectionID() {
      this.$axios
        .get(`${this.$base}/management/users/${this.userInfo.id}`)
        .then(res => {
          if (res.data && res.data.code === 200) {
            const result = res.data.data
            this.searchCollectionId = result.searchCollectionId
            this.SET_SEARCH_COLLECTION_ID(result.searchCollectionId)
          }
        })
    },
    // 使用文件夹id生成报告（将报告保存到该文件夹下)
    async saveReport() {
      console.log(this.judgeReports, this.userInfo, '----->>> judgeReports')
      const url = `${this.$base}/management/user/${this.userInfo.id}/collection`
      const contentToSave = {
        judgeReports: this.judgeReports,
        lawReports: this.lawReports,
        criterionReports: this.criterionReports,
        knowledgeReports: this.knowledgeReports,
        contentReports: this.contentReports,
        anaReports: this.anaReports,
        textReport: this.textReport,
        hasFirstPage: false
      }
      // console.log(contentToSave, 'contentToSave')
      const d = {
        content: JSON.stringify(contentToSave),
        level: 1,
        name: this.tempTitle,
        size: 0,
        parentId: this.searchCollectionId,
        type: 'SEARCHREPORT',
        createTime: new Date().getTime()
      }
      try {
        const { data } = await this.$axios.post(url, d)
        if (data.code === 200) {
          return data.data
        } else {
          return null
        }
      } catch (err) {
        this.$notify('生成报告失败')
        console.log(err)
      }
    },
    // 分享
    async share() {
      const newJudgeReports = []
      this.panliCheckList.forEach(item => {
        const index = this.judgeReports.findIndex(
          val => val.doc_id === item.doc_id
        )
        if (index === -1) {
          newJudgeReports.push(item)
        }
      })
      const arr = [...this.judgeReports, ...newJudgeReports]
      this.setStampReport({
        userId: this.userInfo.id,
        type: 'judgeReports',
        reportData: arr,
        callback: async () => {
          const data = await this.saveReport()
          this.reportId = data.id
          this.savedReportData = data
          const { name, id } = this.savedReportData
          if (name && id) {
            const ua = this.ua
            // console.log(this.$base, id, 'baseurl----')
            let shareUrl = `https://tool.farbun.com/transform/searchReport/${id}`
            if (this.$base === 'https://api.dev.farbun.com') {
              shareUrl = `https://dev.tool.farbun.com/transform/searchReport/${
                this.reportId
              }`
            } else if (this.$base.includes('dev')) {
              shareUrl = `https://dev.tool.farbun.com/transform/searchReport/${
                this.reportId
              }`
            } else {
              shareUrl = `https://tool.farbun.com/transform/searchReport/${
                this.reportId
              }`
            }
            if (ua === this.$ua.a) {
              // eslint-disable-next-line no-undef
              AND2JS.shareCaseFile(name, window.encodeURI(shareUrl))
            } else if (ua === this.$ua.i) {
              window.webkit.messageHandlers.shareCaseFile.postMessage(
                JSON.stringify({
                  filename: name,
                  url: window.encodeURI(shareUrl)
                })
              )
            } else {
              this.$notify('网页端暂不支持分享')
              this.copyLink(shareUrl)
            }
          } else {
            this.$notify('未能获取到需要的数据')
          }
        }
      })

      // console.log(this.judgeReports, '-------------')
    },
    // 复制链接地址
    copyLink(url) {
      let text = url
      text = text.replace(/<[^>]*>/g, '')
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
      this.$notify({
        type: 'success',
        message: '已复制当前链接，请粘贴给需要分享的人'
      })
      // this.$message('已复制当前链接，请粘贴给需要分享的人')
    },
    // 加入收集箱
    joinCollectBox() {
      const newJudgeReports = []
      this.panliCheckList.forEach(item => {
        const index = this.judgeReports.findIndex(
          val => val.doc_id === item.doc_id
        )
        if (index === -1) {
          newJudgeReports.push(item)
        }
      })
      const arr = [...this.judgeReports, ...newJudgeReports]
      console.log('加入收集箱', arr)
      if (this.panliCheckList && this.panliCheckList.length > 0) {
        this.$notify('已加入收集箱')
        this.setStampReport({
          userId: this.userInfo.id,
          type: 'judgeReports',
          reportData: arr
        })
      }
    },
    // 取消
    cancel() {
      this.panliCheckedAll = false
      this.panliChecked = false
      this.panliCheckedNum = 0
      this.caselaelist.map(item => {
        item.checked = false
      })
      this.panliCheckDocIds = []
    },
    // 下载
    downLoad() {
      if (this.panliCheckedNum > 1) {
        this.$notify('只支持单个下载')
      }
      this.caselaelist.map(item => {
        if (item.checked) {
          this.$axios
            .get(`${this.$base}/document/legal/precedent/${item.doc_id}/file`)
            .then(res => {
              if (res.data.code === '200') {
                // eslint-disable-next-line no-undef
                AND2JS.saveCaseFile(item.case_name, res.data.data.path)
              }
            })
            .catch(err => {
              this.$toast('下载失败')
              Promise.reject(err)
            })
        }
      })
    },
    // 复制
    copy() {
      let text = ''
      this.caselaelist.map(item => {
        if (item.checked) {
          text += item.case_name + '\n'
          if (item.court_name) {
            text += item.court_name + '/'
          }
          if (item.case_id) {
            text += item.case_id + '/'
          }
          text += item.pub_date
          text += '\n'
        }
      })
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
      this.$notify({
        type: 'success',
        message: '内容已复制'
      })
    },
    // 单选
    changeCheck() {
      var panliCheckedAll = true
      var panliChecked = false
      var panliCheckedNum = 0
      this.panliCheckDocIds = []
      this.caselaelist.map(item => {
        if (item.checked) {
          panliChecked = true
          panliCheckedNum++
          this.panliCheckDocIds.push(item.doc_id)
        } else {
          panliCheckedAll = false
        }
      })
      this.panliCheckedAll = panliCheckedAll
      this.panliChecked = panliChecked
      this.panliCheckedNum = panliCheckedNum
    },
    // 全选
    changeCheckAll(val) {
      this.panliCheckDocIds = []
      this.caselaelist.map(item => {
        item.checked = val
        this.panliCheckDocIds.push(item.doc_id)
      })
    },
    pushFilterCondition(payload) {
      const temp = []
      payload.map(item => {
        temp.push(item.key)
      })
      const keys = [...new Set(temp)]
      const list = []
      keys.map(key => {
        const obj = {
          key: key,
          value: []
        }
        payload.map(item => {
          if (obj.key === item.key) {
            obj.value.push(item.value[0])
          }
        })
        list.push(obj)
      })
      this.caselaelist = []
      this.panlipage = 1
      this.caselaw(
        this.active,
        this.panliname,
        this.falvname,
        this.zhishiname,
        this.lawname,
        list
      )
    },
    // 侧边栏
    ShowFilter() {
      console.log('侧边连')
      const title = []
      this.caseTree.forEach(item => {
        title.push(item.title)
      })
      this.TreeSelect.flag = true
      this.TreeSelect.data = this.caseTree
      this.TreeSelect.title = title
    },
    lawDetail(id, text, catlog, title) {
      this.lawOptions.law_id = id
      this.lawOptions.title = title
      this.lawOptions.words = [text]
      this.lawOptions.catlog = catlog
      this.lawflag = true
    },
    GoDetail(id, words, forword, titleName) {
      this.detailshow.doc_id = id
      this.detailshow.words = [words]
      this.detailshow.forword = forword
      this.detailshow.titleName = titleName
      this.appealDetail = true
    },
    handleCopy(text) {
      console.log('handleCopy---------->>', this.currentObjTextArr, text)
      if (this.currentObjTextArr.length <= 0) {
        this.currentObjTextArr.push(text)
      } else {
        const index = this.currentObjTextArr.indexOf(text)
        if (index === -1) {
          this.currentObjTextArr.push(text)
        }
      }
      this.$emit('collect', {
        collection: this.collection,
        textArr: this.currentObjTextArr
      })
    },
    emitCollect(payload) {
      if (payload.isCancle) {
        if (this.collection.length) {
          this.collection.forEach((item, index) => {
            if (item.name === payload.name) {
              this.collection.splice(index, 1)
            }
          })
        }
        this.$emit('collect', {
          collection: this.collection,
          textArr: this.currentObjTextArr
        })
      } else {
        const index = this.collection.findIndex(item => {
          return item.name === payload.name
        })
        if (index === -1) {
          this.collection.push(payload)
        } else {
          this.collection[index] = payload
        }
        this.$emit('collect', {
          collection: this.collection,
          textArr: this.currentObjTextArr
        })
      }
      // console.log(this.collection, payload, '-------- emitCollect -------')
    },
    changeActive(index, panliname, falvname, zhishiname, lawname) {
      console.log('index', index)
      this.active = index
      if (this.active.toString()) {
        this.caselaelist = []
        this.panlipage = 1
        if (this.hatin) {
          this.caselaw(index, panliname, falvname, zhishiname, lawname)
        }
      }
    },
    next(val) {
      this.panlipage++
      this.caselaw(
        val,
        this.panliname,
        this.falvname,
        this.zhishiname,
        this.lawname
      )
    },
    // 点击判例法律只是出列表
    caselaw(
      val,
      panliname,
      falvname,
      zhishiname,
      lawname,
      filterCondition = []
    ) {
      const index = lawname.indexOf('【')
      let Nnewname = ''
      if (index === -1) {
        Nnewname = lawname
      } else {
        Nnewname = lawname.substr(0, index)
      }
      lawname = Nnewname
      this.loading = true
      this.knowledge = true
      this.panliname = panliname
      this.falvname = falvname
      this.zhishiname = zhishiname
      this.lawname = lawname
      this.hatin = false
      if (val === 0) {
        const text = this.lawContent.case_name + this.lawname
        setTimeout(() => {
          this.$axios
            .post(`${pydataBase}/api/law/relatedPrecedent/caseTreeSearch`, {
              law: text
            })
            .then(res => {
              if (res.data.code === 200) {
                this.caseTree = res.data.result
              }
              this.$axios
                .post(`${pydataBase}/api/law/relatedPrecedent/caseSearch`, {
                  offset: this.panlipage,
                  law: text,
                  filter_condition: filterCondition
                })
                .then(res => {
                  this.loading = false
                  if (res.data.result.length === 0) {
                    this.$toast('暂无数据')
                    setTimeout(() => {
                      document.querySelector('.van-toast--text').style.zIndex =
                        '10000000000000000000000'
                      document.querySelector('.van-toast--text').style.display =
                        'block'
                    })
                  } else {
                    res.data.result.map(item => {
                      item.checked = this.panliCheckedAll
                    })
                  }
                  this.caselaelist = this.caselaelist.concat(res.data.result)
                  this.panliCheckedNum = 0
                  this.caselaelist.forEach(item => {
                    if (
                      this.panliCheckDocIds &&
                      this.panliCheckDocIds.indexOf(item.doc_id) !== -1
                    ) {
                      item.checked = true
                      this.panliCheckedNum++
                    } else {
                      item.checked = false
                    }
                  })
                  if (this.panliCheckedNum === 0) {
                    this.panliCheckedAll = false
                    this.panliChecked = false
                  } else {
                    this.panliChecked = true
                  }
                })
                .catch(() => {
                  this.$toast('暂无数据')
                  this.loading = false
                })
            })
        })
      } else if (val === 1) {
        // setTimeout(() => {
        //   this.$axios
        //     .get(
        //       `${pydataBase}/api/nvi/law/detail/quote_law?title=${
        //         this.lawContent.case_name
        //       }&name=${this.lawname}&offset=${this.panlipage}`
        //     )
        //     .then(res => {
        //       this.loading = false
        //       if (res.data.result.data.length === 0) {
        //         this.$toast('暂无数据')
        //         setTimeout(() => {
        //           document.querySelector('.van-toast--text').style.zIndex =
        //             '10000000000000000000000'
        //           document.querySelector('.van-toast--text').style.display =
        //             'block'
        //         })
        //       }
        //       this.caselaelist = this.caselaelist.concat(res.data.result.data)
        //     })
        // })
        setTimeout(() => {
          this.$axios
            .get(
              `${pydataBase}/api/nvi/law/detail/quote_ledge?title=${
                this.lawContent.case_name
              }&name=${this.lawname}&offset=${this.panlipage}`
            )
            .then(res => {
              this.loading = false
              if (res.data.result.data.length === 0) {
                this.$toast('暂无数据')
                setTimeout(() => {
                  document.querySelector('.van-toast--text').style.zIndex =
                    '10000000000000000000000'
                  document.querySelector('.van-toast--text').style.display =
                    'block'
                })
              }
              this.caselaelist = this.caselaelist.concat(res.data.result.data)
            })
        })
      } else if (val === 2) {
        setTimeout(() => {
          this.$axios
            .get(
              `${pydataBase}/api/nvi/law/detail/quote_ledge?title=${
                this.lawContent.case_name
              }&name=${this.lawname}&offset=${this.panlipage}`
            )
            .then(res => {
              this.loading = false
              if (res.data.result.data.length === 0) {
                this.$toast('暂无数据')
                setTimeout(() => {
                  document.querySelector('.van-toast--text').style.zIndex =
                    '10000000000000000000000'
                  document.querySelector('.van-toast--text').style.display =
                    'block'
                })
              }
              this.caselaelist = this.caselaelist.concat(res.data.result.data)
            })
        })
      }
      setTimeout(() => {
        this.hatin = true
      })
    },
    nextPage() {
      this.offset++
      this.getData()
    },
    showTip(e) {
      Array.from(document.querySelectorAll('label .tips')).forEach(item => {
        if (item === e.target.nextElementSibling.children[1]) {
          return
        }
        item.style.opacity = '0'
      })
      if (
        e.target.nextElementSibling.children[1].style.opacity === '0' ||
        e.target.nextElementSibling.children[1].style.opacity === ''
      ) {
        e.target.nextElementSibling.children[1].style.opacity = '0.5'
      } else {
        e.target.nextElementSibling.children[1].style.opacity = '0'
      }
    },
    showOtherPage(clipData) {
      this.clip_data = clipData
      this.show = true
    },
    // 获取关联法律数据
    getData() {
      this.isLoading = true
      const index = this.name.indexOf('【')
      let Nnewname = ''
      if (index === -1) {
        Nnewname = this.name
      } else {
        Nnewname = this.name.substr(0, index)
      }
      this.$axios
        .get(`${pydataBase}/api/nvi/law/detail/relate_law`, {
          params: {
            law_id: this.lawid,
            name: Nnewname,
            offset: this.offset
          }
        })
        .then(res => {
          this.isLoading = false
          this.data = this.data.concat(res.data.result)
        })
        .catch(err => {
          this.isLoading = false
          console.log(err)
        })
    },
    lawclick(res) {
      console.log('res---law 点击')
      console.log(this.TreeSelect)
    },
    content(array) {
      for (let a = 0; a < array.length; a++) {
        if (array[a].type && array[a].type === '章') {
          array[a].type = 'zhang'
        } else if (array[a].type && array[a].type === '条') {
          array[a].type = '条'
        } else if (array[a].type && array[a].type === '编') {
          array[a].type = 'bian'
        } else if (array[a].type && array[a].type === '节') {
          array[a].type = 'jie'
        }
      }
      return array
    },
    contentV(array) {
      for (let a = 0; a < array.length; a++) {
        if (array[a].type && array[a].type === '章') {
          array[a].type = 'zhang'
        } else if (array[a].type && array[a].type === '条') {
          array[a].type = '条'
        } else if (array[a].type && array[a].type === '编') {
          array[a].type = 'bian'
        } else if (array[a].type && array[a].type === '节') {
          array[a].type = 'jie'
        }
      }
      return array
    }
  },
  watch: {
    clearCheck(val) {
      if (val) {
        this.collection = []
        this.$emit('collect', this.collection)
      }
    },
    active(val) {
      console.log(val)
      if (val.toString()) {
        this.caselaelist = []
        this.panlipage = 1
        if (this.hatin) {
          this.caselaw(
            val,
            this.panliname,
            this.falvname,
            this.zhishiname,
            this.lawname
          )
        }
      }
    },
    show(val) {
      if (!val) {
        this.name = ''
        this.offset = 0
        this.data = []
      }
    },
    lawContent(val) {
      this.lawTitle = val.case_name
      // console.log('lawcontent---2222', this.lawTitle)
    }
  },
  created() {
    this.getCollectionID()
  },
  mounted() {
    // console.log('lawcontent创建')
    setTimeout(() => {
      if (this.catlog) {
        const container = document.querySelector('#' + this.catlog)
        if (container) {
          container.scrollIntoView({ block: 'center' })
        }
      }
      this.lawTitle = this.lawContent.case_name
    })

    console.log('lawContent.vue ------>>> mounted')
    this.$bus.$on(
      'changeActiveFn',
      (activeindex, panliname, falvname, zhishiname, lawname) => {
        // let times = 0
        // times++
        // console.log('收到changeActiveFn :', times)
        this.changeActive(activeindex, panliname, falvname, zhishiname, lawname)
      }
    )
    if (this.contentReports && this.contentReports.length) {
      const index = this.contentReports.findIndex(
        item => item.id === this.lawid
      )
      if (index === -1) {
        this.currentObj = null
      } else {
        this.currentObj = this.contentReports[index]
      }
      if (this.currentObj && this.currentObj.arr) {
        this.collection = this.currentObj.arr
      }
      if (this.currentObj) {
        this.currentObjTextArr = this.currentObj.textArr
          ? this.currentObj.textArr
          : []
      }
    }
    this.$bus.$on('clonelawclick', res => {
      console.log('收到lawclick')
      this.lawclick(res)
    })
  }
}
</script>
<style lang="stylus">
.van-notify
  z-index: 99999999 !important
.law-citation
  width 100%
  height 100%
  position: fixed;
  z-index: 10000;
  top: 0px;
  left: 0px;
  overflow: auto;
  background #FFF
  padding-top 44px
  & .fb-header
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;
    width 100%
.detail_area
  width 100%
  height 100%
  position fixed
  top 0px
  left 0px
  background-color white
  z-index 10001
.panli
  width 90%
  margin 0px auto
  .title
    font-size 15px
    color #333
    font-weight bold
    margin-top 10px
  .contet
    border-bottom 1px solid #ccc
    div:nth-child(1)
      font-size 15px
      color #333
      font-weight bold
      margin-top 10px
    div:nth-child(2)
      font-size 14px
      color #ccc
      margin-top 10px
      margin-bottom 10px
  & .timeliness
    color red
    text-align center
    text-align center
    line-height 35px
    min-width 90px
    background pink
    height 35px
    z-index 999
    opacity 0.5
    border-radius 3px
    display inline-block
.name.tiao
  position relative
  & span
    position absolute
    margin-top 2px
    margin-left 60px
    cursor pointer
    display inline-block
    font-size 14px
    width 130px
    text-align left
    color rgb(21, 39, 212)
    opacity 0.5
    transition all 0.5s
em
  color red
  font-weight 600
.zhang
  font-size 18px
  color rgba(0, 0, 0, 1)
  text-align center
  margin-top 23px
  width 100%
  span
    font-family PingFang-SC-Medium
    font-weight bold
.lawJie
  font-size 18px
  color rgba(0, 0, 0, 1)
  width 100%
  margin-top 21px
  .tips
    color rgb(21, 39, 212)
    opacity 0
    width 300px
    transition all 0.5s
    display inline-block
  .Tips
    &:hover
      .tips
        opacity 0.5
  .tipforrefer
    box-sizing border-box
    padding 5px
    text-align center
    color #3cdcbb
    background-color #f5f5f5
    transition all 0.5s
    position absolute
    top 120%
    right 0px
    font-size 14px
    white-space nowrap
    border-radius 3px
    opacity 0
  #refer
    &:hover
      color #3cdcbb
      .tipforrefer
        opacity 1
  span
    font-family PingFang-SC-Medium
.zhang1
  display flex
  flex-wrap wrap
.lawZhangContent
  margin-top 15px
  span
    font-size 15px
    font-family PingFang-SC-Regular
    font-weight 400
    color rgba(51, 51, 51, 1)
    line-height 24px
    // display flex
    flex-wrap wrap
    em
      color red
.tiao
  font-size 16px
  font-family PingFang-SC-Medium
  font-weight bold
  color rgba(0, 0, 0, 1)
  margin-top 20px
.clip-data
  width 100%
  height 100%
  overflow auto
  padding 20px 10px
  box-sizing border-box
  & .clip-data-box
    & .clip-data-box-title
      font-size 12px
      color #FF9900
    & .clip-data-box-content
      & .clip-data-box-content-title
        color #000
        font-size 12px
        font-weight bold
        margin-bottom 5px
        cursor pointer
      & .clip-data-box-content-text
        font-size 14px
        cursor pointer
        color #666666
        margin-bottom 5px
        width 100%
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
.othertitle
  width 100%
  text-align center
  font-size 16px
  margin-top 5px
  color #333
.othercontent
  width 100%
  height calc(100% - 42px)
  overflow auto
  li
    padding 10px
  .law-title
    font-weight 600
    font-size 14px
  .law
    margin-top 10px
    font-size 14px
    font-weight 600
  .law-content
    margin-top 10px
    font-size 14px
  .nextpage
    font-size 14px
    color #cccccc
    margin-top 5px
    text-align center
</style>
